import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const openAiService = createApi({
  reducerPath: "openAi",
  tagTypes: "openAiS",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      openAiCompletionRequest: builder.mutation({
        query: (data) => {
          return {
            url: "api/openAiCompletionRequest",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["openAiS"],
      }),

      // New API in Array resp
      openAiCompletionRequestArray: builder.mutation({
        query: (data) => {
          return {
            url: "api/openAiCompletionRequestArray",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["openAiS"],
      }),
      //  API in headline Array resp with Prompt engineering
      openAiCompletionRequestHeadlineArray: builder.mutation({
        query: (data) => {
          return {
            url: "api/openAiCompletionRequestHeadlineArray",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["openAiS"],
      }),

      //  API in headline Array resp with Prompt engineering
      openAiCompletionRequestDescriptionArray: builder.mutation({
        query: (data) => {
          return {
            url: "api/openAiCompletionRequestDescriptionArray",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["openAiS"],
      }),

      // API for Get URL summery
      openAiUrlSummary: builder.mutation({
        query: (data) => {
          return {
            url: "api/openAiUrlSummary",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["openAiS"],
      }),

      // Generate Category
      openAiGenerateCategory: builder.mutation({
        query: (data) => {
          return {
            url: "api/generateCategory",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["openAiS"],
      }),

      getOpenAi: builder.query({
        query: (page) => {
          return {
            url: ``,
            method: "api/GET",
          };
        },
        providesTags: ["openAiS"],
      }),
    };
  },
});
export const {
  useOpenAiCompletionRequestMutation,
  useOpenAiCompletionRequestArrayMutation,
  useOpenAiCompletionRequestHeadlineArrayMutation,
  useOpenAiCompletionRequestDescriptionArrayMutation,
  useOpenAiUrlSummaryMutation,
  useOpenAiGenerateCategoryMutation,
  useGetOpenAiQuery,
} = openAiService;
export default openAiService;
