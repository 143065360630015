import React from "react";
import Lottie from "react-lottie";
import AiRobot from "./lotties/AiRobot.json";

const AiLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AiRobot,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="loader-cmp flex items-center absolute h-full w-full">
      {/* <Lottie options={defaultOptions} height={200} width={200} /> */}
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default AiLoader;
