import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const lang = JSON.parse(localStorage.getItem("lang"));
const globalData = JSON.parse(localStorage.getItem("globalData"));
const localData = JSON.parse(localStorage.getItem("localData"));
const expendRow = JSON.parse(localStorage.getItem("expendRow"));
const selectedType = JSON.parse(localStorage.getItem("selectedType"));
const formInfo = JSON.parse(localStorage.getItem("formInfo"));
const inputDataDetail = JSON.parse(localStorage.getItem("inputDataDetail"));
const csvReadedData = JSON.parse(localStorage.getItem("csvReadedData"));

const globalReducer = createSlice({
  name: "global",
  initialState: {
    success: "",
    searchBar: false,
    lang: lang
      ? lang
      : {
          label: "English",
          value: "english",
        },
    localData: localData ? localData : [],
    globalData: globalData
      ? globalData
      : {
          headlines: [],
          description: [],
        },
    expendRow: expendRow
      ? expendRow
      : {
          status: true,
          index: 0,
        },
    selectedType: selectedType
      ? selectedType
      : {
          lbl: "headline",
          index: 0,
        },
    formInfo: formInfo
      ? formInfo
      : { index: "", pName: "", pDesc: "", urlDesc: "" },
    csvReadedData: csvReadedData ? csvReadedData : [],
    inputDataDetail: inputDataDetail ? inputDataDetail : [],
  },
  reducers: {
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    clearMessage: (state) => {
      state.success = "";
    },
    toggleSearchBar: (state) => {
      state.searchBar = !state.searchBar;
    },
    setGlobalData: (state, action) => {
      try {
        localStorage.setItem("globalData", JSON.stringify(action.payload));
        state.globalData = action.payload;
      } catch (error) {
        console.log("error", error);
        toast.error("Local Storage Limit exceeded");
      }
    },
    setLocalData: (state, action) => {
      try {
        localStorage.setItem("localData", JSON.stringify(action.payload));
        state.localData = action.payload;
      } catch (error) {
        toast.error("LocalStorage Limit exceeded");
      }
    },
    setcsvReadedData: (state, action) => {
      localStorage.setItem("csvReadedData", JSON.stringify(action.payload));
      state.csvReadedData = action.payload;
    },

    setExpendRow: (state, action) => {
      localStorage.setItem("expendRow", JSON.stringify(action.payload));
      state.expendRow = action.payload;
    },
    setSelectedType: (state, action) => {
      localStorage.setItem("selectedType", JSON.stringify(action.payload));
      state.selectedType = action.payload;
    },
    setFormInfo: (state, action) => {
      localStorage.setItem("formInfo", JSON.stringify(action.payload));
      state.formInfo = action.payload;
    },
    setInputDataDetail: (state, action) => {
      localStorage.setItem("inputDataDetail", JSON.stringify(action.payload));
      state.inputDataDetail = action.payload;
    },
    setLang: (state, action) => {
      localStorage.setItem("lang", JSON.stringify(action.payload));
      state.lang = action.payload;
    },
    deleteLocalData: (state, { payload }) => {
      localStorage.removeItem(payload);
      localStorage.removeItem("localData");
      localStorage.removeItem("globalData");
      localStorage.removeItem("expendRow");
      localStorage.removeItem("selectedType");
      localStorage.removeItem("formInfo");
      localStorage.removeItem("inputDataDetail");
      localStorage.removeItem("csvReadedData");
      state.localData = null;
    },
  },
});
export const {
  setSuccess,
  clearMessage,
  toggleSearchBar,
  setGlobalData,
  setLocalData,
  deleteLocalData,
  setExpendRow,
  setSelectedType,
  setFormInfo,
  setcsvReadedData,
  setInputDataDetail,
  setLang,
} = globalReducer.actions;
export default globalReducer.reducer;
