import React, { useState, useEffect } from "react";
import { ArrowUpIcon, ArrowDownIcon } from "../../assets/Icons";
const WofDropDown = ({
  headlinesPositionList,
  handlePosition,
  headlinePositionVal,
}) => {
  const [hide, setHide] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(headlinePositionVal);
  useEffect(() => {
    document.addEventListener("click", () => setHide(false));
  }, []);

  return (
    <div className="dropDown flex aic col rel">
      <div className="category flex aic">
        <div
          className="box cleanbtn flex aic rel"
          onClick={(e) => {
            e.stopPropagation();
            setHide(!hide);
          }}
        >
          <div className="slt flex aic">
            <div className="lbl font s14 b5">
              {selectedPosition?.position ? selectedPosition?.position : "--"}
            </div>
          </div>
          <div className="arrow">
            {hide ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
        </div>
      </div>
      <div className={`block flex aic abs ${hide ? "show" : ""}`}>
        <div className="manue flex aic col anim">
          {headlinesPositionList.map((item, idex) => (
            <div
              key={idex}
              className="slt flex aic"
              onClick={(e) => {
                setSelectedPosition(item);
                setHide(!hide);
                handlePosition(item);
              }}
            >
              <div className="lbl flex aic font s14 b4">{item?.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WofDropDown;
