import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CloseIcon } from "../../assets/Icons";
import LoaderWhite from "../LoaderWhite";
import { useOpenAiUrlSummaryMutation } from "../../store/services/openAiService";

import {
  setWoGlobalFileForm,
  setInputWoDataDetail,
} from "../../store/reducers/woFileReducer";

const GenerateUrlSummaryWoF = ({ setOpenGenerateUrl, modelData }) => {
  const dispatch = useDispatch();
  const { item, index } = modelData;
  const { woGlobalFileForm, inputWoDataDetail } = useSelector(
    (state) => state.woFileReducer
  );
  const { userId } = useSelector((state) => state.authReducer);
  const [completionRequest, data] = useOpenAiUrlSummaryMutation();
  let urlRes = data?.data?.urlSummary;
  let toneVoiceRes = data?.data?.toneOfVoice;

  const initialValues = {
    url: item?.finalURL,
  };
  const validate = (values) => {
    const errors = {};
    const url = values.url.trim();

    if (!url) {
      errors.url = "URL is required";
    } else if (!/^https?:\/\//i.test(values.url)) {
      errors.url = "Please add http:// or https:// before your url";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      completionRequest({
        landing_page_url: values.url,
      });
    },
  });

  const myObject = inputWoDataDetail.find((item) => item.index === index);
  const [desc, setDesc] = useState(urlRes ? urlRes : myObject?.pDesc);
  const [toneVoiceDesc, setToneVoiceDesc] = useState(
    toneVoiceRes ? toneVoiceRes : myObject?.toneVoiceDesc
  );

  useEffect(() => {
    if (data.isSuccess) {
      let textVal = urlRes;
      let textToneVoice = toneVoiceRes;
      setDesc(textVal?.trim());
      setToneVoiceDesc(textToneVoice?.trim());
      const location = inputWoDataDetail.findIndex(
        (obj) => obj.index === index
      );

      const myObject = inputWoDataDetail.find((item) => item.index === index);

      if (index === 1112) {
        dispatch(
          setWoGlobalFileForm({
            ...woGlobalFileForm,
            pDesc: textVal?.trim(),
            urlDesc: textVal?.trim(),
            toneVoiceDesc: textToneVoice?.trim(),
          })
        );
      } else {
        if (location === -1) {
          dispatch(
            setInputWoDataDetail([
              ...inputWoDataDetail,
              {
                index,
                pName: myObject ? myObject?.pName : "",
                pDesc: textVal?.trim(),
                urlDesc: textVal?.trim(),
                toneVoiceDesc: textToneVoice?.trim(),
              },
            ])
          );
        } else {
          dispatch(
            setInputWoDataDetail([
              ...inputWoDataDetail.slice(0, location),
              {
                index,
                pName: myObject ? myObject?.pName : "",
                pDesc: textVal?.trim(),
                urlDesc: textVal?.trim(),
                toneVoiceDesc: textToneVoice?.trim(),
              },
              ...inputWoDataDetail.slice(location + 1),
            ])
          );
        }
      }
    }
  }, [data.isSuccess]);

  return (
    <div className="generate-url-summary flex">
      <div className="generate-url-summary-block flex flex-col">
        <div className="block-hdr flex items-center justify-end">
          <div
            className="icon flex items-center justify-center cursor-pointer"
            onClick={(e) => setOpenGenerateUrl(false)}
          >
            <CloseIcon />
          </div>
        </div>
        {data.isLoading ? (
          <LoaderWhite />
        ) : (
          <>
            <form onSubmit={formik.handleSubmit}>
              <div className="field flex flex-col">
                <label className="lbl" htmlFor="url">
                  URL
                </label>
                <input
                  id="url"
                  name="url"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.url}
                  className="min-h-[30px] txt-area"
                  placeholder="https://example.com"
                />
                {formik.touched.url && formik.errors.url ? (
                  <div className="text-white p-2 text-sm font-medium">
                    {formik.errors.url}
                  </div>
                ) : null}
                <button className="btn button" type="submit">
                  Generate Summary
                </button>
              </div>

              {/* <div className="field flex flex-col">
                  <div className="lbl">URL</div>
                  <input
                    className="min-h-[30px] txt-area"
                    value={url}
                    onChange={(e) => setURL(e.target.value)}
                  />
                  <button
                    className="btn button"
                    onClick={(e) => submitUrlSummary()}
                  >
                    Generate Summary
                  </button>
                </div> */}
            </form>
            <div className="field flex flex-col">
              <div className="lbl">Landing Page Summary</div>
              <textarea
                className="min-h-[140px] txt-area"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
              <CopyToClipboard text={`${desc}`}>
                <button
                  className="btn button"
                  onClick={(e) => setOpenGenerateUrl(false)}
                >
                  Copy To Clipboard
                </button>
              </CopyToClipboard>
            </div>
            <div className="field flex flex-col">
              <div className="lbl">Landing Page Tone of Voice</div>
              <textarea
                className="min-h-[140px] txt-area"
                value={toneVoiceDesc}
                onChange={(e) => setToneVoiceDesc(e.target.value)}
              />
              <CopyToClipboard text={`${toneVoiceDesc}`}>
                <button
                  className="btn button"
                  onClick={(e) => setOpenGenerateUrl(false)}
                >
                  Copy To Clipboard
                </button>
              </CopyToClipboard>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GenerateUrlSummaryWoF;
