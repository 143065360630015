import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CloseIcon } from "../../../assets/Icons";
import {
  setWoGlobalFileData,
  setWoGlobalFileForm,
  setWoFileJSON,
  setWoFileJSONDownload
} from "../../../store/reducers/woFileReducer";
const EditRow = ({ setOpenEditRow, modelData }) => {
  const dispatch = useDispatch();
  const { woGlobalFileData, woGlobalFileForm, woFileJSON,woFileJSONDownload } = useSelector(
    (state) => state.woFileReducer
  );
  const { item, index } = modelData;
  const [arrayObj, setArrayObj] = useState(woFileJSON ? woFileJSON[index] : []);
  const [rowData, setRowData] = useState(item);
  const [modifieddata,setmodifieddata] = useState({campaign : item?.campaign,adGroup : item?.adGroup,path1 : item?.path1,path2: item?.path2,finalURL : item?.finalURL})
  const handleRowData = (e) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
    setmodifieddata({...modifieddata,[e.target.name]: e.target.value})
  };

  const updateRowData = async () => {
    setArrayObj(rowData);
    // modify downloadJSON data
    let woFileJSONDownloadTemp = [...woFileJSONDownload];
    let rowindex = woFileJSONDownload?.findIndex(
      (item) => item?.index === index
    );
    if(rowindex > -1){
      woFileJSONDownloadTemp[rowindex] = {
        ...woFileJSONDownloadTemp[rowindex],
        ...modifieddata
      };
    }
    dispatch(setWoFileJSONDownload(woFileJSONDownloadTemp));
    // close 
    setOpenEditRow(false);
  };
  useEffect(() => {
    var nArr = [...woFileJSON];
    nArr[index] = arrayObj;
    dispatch(setWoFileJSON(nArr));
  }, [arrayObj]);

  return (
    <div className="tbl-row-edit flex">
      <div className="model-block flex flex-col">
        <div className="model-hdr flex items-center justify-center mb-8">
          <div className="mode-hdr-title font-semibold text-xl flex-1 text-center text-white">
            Provide more Ad details
          </div>
          <div
            className="cross-icon flex items-center justify-center cursor-pointer"
            onClick={(e) => setOpenEditRow(false)}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="row2">
          <div className="input-field flex flex-col">
            <div className="input-tag">Final URL</div>
            <input
              type="url"
              name="finalURL"
              value={rowData?.finalURL}
              onChange={handleRowData}
              placeholder="Final URL"
              className="model-val h-full w-full rounded-md py-1 px-4"
            />
          </div>

          <div className="input-field flex flex-col">
            <div className="input-tag">Campaign</div>
            <input
              type="text"
              name="campaign"
              value={rowData?.campaign}
              onChange={handleRowData}
              placeholder="Campaign"
              className="model-val h-full w-full rounded-md py-1 px-4"
            />
          </div>

          <div className="input-field flex flex-col">
            <div className="input-tag">Ad Group</div>
            <input
              type="text"
              name="adGroup"
              value={rowData?.adGroup}
              onChange={handleRowData}
              placeholder="Ad Group"
              className="model-val h-full w-full rounded-md py-1 px-4"
            />
          </div>

          <div className="input-field flex flex-col">
            <div className="input-tag">Path 1</div>
            <input
              type="text"
              name="path1"
              value={rowData?.path1}
              onChange={handleRowData}
              placeholder="Path 1"
              className="model-val h-full w-full rounded-md py-1 px-4"
            />
          </div>
          <div className="input-field flex flex-col">
            <div className="input-tag">Path 2</div>
            <input
              type="text"
              name="path2"
              value={rowData?.path2}
              onChange={handleRowData}
              placeholder="Path 2"
              className="model-val h-full w-full rounded-md py-1 px-4"
            />
          </div>
        </div>

        <div className="model-item flex items-center justify-center mt-8">
          <button className="button btn-update" onClick={updateRowData}>
            Update Ad
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditRow;
