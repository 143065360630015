import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/reducers/authReducer";
import { deleteLocalData } from "../../store/reducers/globalReducer";
import { useGetUserDataQuery } from "../../store/services/userService";
import Modal from "../Modal";
import VideoModel from "../VideoModel";

const Header = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.authReducer);
  const { data, isSuccess, isLoading } = useGetUserDataQuery(userId);
  const [open, setOpen] = useState(false);

  const userLogout = () => {
    dispatch(logout("userToken"));
    dispatch(deleteLocalData("localData"));
  };

  return (
    <div className="header-camp flex">
      <div className="wrapWidth wrap flex items-center">
        <div className="left flex items-center">
          <div className="logo-img flex items-center justify-center mr-2">
            <Link to="/">
              <img src="../images/logo1.png" className="logo" />
            </Link>
          </div>
          <div className="demo-limit flex items-center">
            <div className="limit-lbl flex items-center justify-center">
              Your free demo usage
            </div>
            {isSuccess && (
              <div className="limit-val">
                {(
                  (data?.totalUsageTokens / data?.totalTokensLimitDemo) *
                  100
                ).toFixed(2)}
                %
              </div>
            )}
          </div>
        </div>
        <div className="right flex items-center justify-end">
          <div className="menu-list flex items-center">
            <div
              className="menu-item flex items-center justify-center"
              onClick={(e) => setOpen(true)}
            >
              User Guide
            </div>
          </div>
          <div className="logout-action flex items-center">
            <div className="btn button" onClick={userLogout}>
              LOGOUT
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <VideoModel setOpen={setOpen} />
      </Modal>
    </div>
  );
};

export default Header;
