import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { EditIcon, CloudIcon } from "../../assets/Icons";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import AiLoader from "../AiLoader";
import DropDown from "../DropDown";
import { toast } from "react-toastify";

import {
  setLocalData,
  deleteLocalData,
  setcsvReadedData,
  setInputDataDetail,
} from "../../store/reducers/globalReducer";
import {
  useOpenAiCompletionRequestArrayMutation,
  useOpenAiCompletionRequestDescriptionArrayMutation,
} from "../../store/services/openAiService";
import { useOpenAi4DescriptionArrayMutation } from "../../store/services/openAi4Service";
import UpdateDescriptionModel from "../../components/UpdateDescriptionModel";

const RowExpendDescription = ({
  item,
  index,
  setExpendRow,
  selectedDescriptions,
  setselectedDescriptions,
  localDataDescriptionkeys,
  setOpenGenerateUrl,
  setModelData,
}) => {
  const dispatch = useDispatch();
  const { localData, csvReadedData, formInfo, inputDataDetail, lang } =
    useSelector((state) => state.globalReducer);
  const { userId } = useSelector((state) => state.authReducer);

  const [completionRequest, data] = useOpenAi4DescriptionArrayMutation();
  const errors = data?.error ? data?.error : [];

  const [openUpdateDescription, setOpenUpdateDescription] = useState(false);
  const [selectedDescriptionVal, setSelectedDescriptionVal] = useState();
  const [updatedDescriptionVal, setUpdatedDescriptionVal] = useState();

  const [mod_localDataDescriptionkeys, setmod_localDataDescriptionkeys] =
    useState([...localDataDescriptionkeys]);

  useEffect(() => {
    setmod_localDataDescriptionkeys([...mod_localDataDescriptionkeys]);
  }, [localDataDescriptionkeys]);

  const toastObject = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
  };

  const [userInputProductName, setUserInputProductName] = useState(
    inputDataDetail[index]?.name ? inputDataDetail[index]?.name : ""
  );
  const [userInputProductDescription, setUserInputProductDescription] =
    useState(
      inputDataDetail[index]?.pDesc ? inputDataDetail[index]?.pDesc : ""
    );
  const [useInputProductToneVoice, setUseInputProductToneVoice] = useState(
    inputDataDetail[index]?.toneVoiceDesc
      ? inputDataDetail[index]?.toneVoiceDesc
      : ""
  );

  const handleInput = () => {
    setTimeout(() => {
      const updatedObject = {
        index,
        pName: userInputProductName,
        pDesc: userInputProductDescription,
        toneVoiceDesc: useInputProductToneVoice,
        urlDesc: userInputProductDescription,
      };
      const updatedArray = inputDataDetail.map((object) => {
        if (object.index === index) {
          return updatedObject;
        } else {
          return object;
        }
      });
      if (!updatedArray.some((object) => object.index === index)) {
        updatedArray.push(updatedObject);
      }
      dispatch(setInputDataDetail(updatedArray));
    }, 5000);
  };

  const submitCompletionRequestDescription = (e) => {
    if (userInputProductName === "") {
      toast.error("Enter Product Name!", toastObject);
    } else if (userInputProductDescription === "") {
      toast.error("Enter Product Description!", toastObject);
    } else {
      if (lang.value === "german") {
        completionRequest({
          userId: userId,
          model: "text-davinci-003",
          prompt: `Erstelle eine überzeugende Google Ads Beschreibung für das folgende Produkt. Stelle sicher, dass die Beschreibung maximal 90 Zeichen lang ist. Wie du weißt, können Personen auf deutsch formell und informell angesprochen werden. Wenn du den Nutzer im Text direkt ansprichst, verwende die informelle Ansprache “Du” in der zweiten Person Singular oder “Ihr” in der zweiten Person Plural. Zeichenbeschränkung: Description 1 - 90 Zeichen Description 2 - 90 Zeichen Beispiele: Description 1 - Ob zentrale Apartments, günstige Zimmer oder idyllische Ferienhäuser: Lass es Airbnb sein. Description 2 - Sichere Buchung & flexible Stornierungsmöglichkeiten. Jetzt Urlaub sorgenfrei planen! Product Name: ${userInputProductName} Product Description:  ${userInputProductDescription} Tone of Voice : ${useInputProductToneVoice} Description 1:`,
        });
      } else if (lang.value === "germanF") {
        completionRequest({
          userId: userId,
          systemContent: `Du bist professioneller Texter für Google Ads Beschreibungen.
          Du arbeitest für eine Firma, die Anzeigentexte für Google Ads Werbekunden schreibt.
Google Ads Beschreibungen ergänzen Google Ads Überschriften. Die Überschriften gewinnen normalerweise die Aufmerksamkeit der Nutzer und regen sie dazu an, mehr über das Produkt erfahren zu wollen.
In den Google Ads Beschreibungen finden die Nutzer dann weitere Informationen über das Produkt oder die Dienstleistung, die sie davon überzeugen, auf die Anzeige zu klicken, um sich noch weiter zu informieren und dann letztendlich das Produkt zu kaufen, die Dienstleistung zu buchen oder weitere Informationen anzufragen.
Genau wie Überschriften haben auch Beschreibungen eine maximale Zeichenanzahl, allerdings sind sie länger als Überschriften. Du kannst maximal 90 Zeichen verwenden und hast mehr Raum, um kreativ zu sein.
Verwende die formelle Ansprache “Sie”, wenn der Nutzer im Text direkt angesprochen wird`,

          userContent: `Erstelle überzeugend Google Ads Beschreibungen für das folgende Produkt auf Deutsch.
Beschreibungen bestehen normalerweise aus ein oder zwei ganzen Sätzen und folgen häufig der folgenden Struktur: Unser Produkt /unsere Dienstleistung ist (ein Produkt / eine Dienstleistung), die dir (dem Kunden) bei (einem Problem) mit (einer Lösung) hilft.
Eine weitere mögliche Struktur für Beschreibungen ist es, Nutzern zuerst das Problem vorzustellen, das sie möglicherweise erfahren, weil sie das Produkt noch nicht nutzen und dann die Lösung und wie es ihnen ergeht, wenn das Problem gelöst ist.
Beschreibungen können auch verwendet werden, um aufzuzeigen, wie einfach es ist in Produkt zu verwenden oder eine Dienstleistung zu nutzen.
 Dein Output sollt aus 5 Beschreibungen mit minimal 65 und maximal 90 Zeichen bestehen, die alle unabhängig voneinander das Produkt oder die Dienstleistung bewerben. Numeriere die einzelnen ergebnisse nicht und verwende keine Zeilenumbrüche in den Beschreibungen.
Die einzelnen Beschreibungen werden durch einen vertikalen Trennstrich ("|") voneinander getrennt angezeigt.
Product Name: ${userInputProductName}
Product Description: ${userInputProductDescription}
Tone of Voice : ${useInputProductToneVoice}
Ad Description`,
        });
      } else if (lang.value === "germanInF") {
        completionRequest({
          userId: userId,
          systemContent: `Du bist professioneller Texter für Google Ads Beschreibungen.
          Du arbeitest für eine Firma, die Anzeigentexte für Google Ads Werbekunden schreibt.
Google Ads Beschreibungen ergänzen Google Ads Überschriften. Die Überschriften gewinnen normalerweise die Aufmerksamkeit der Nutzer und regen sie dazu an, mehr über das Produkt erfahren zu wollen.
In den Google Ads Beschreibungen finden die Nutzer dann weitere Informationen über das Produkt oder die Dienstleistung, die sie davon überzeugen, auf die Anzeige zu klicken, um sich noch weiter zu informieren und dann letztendlich das Produkt zu kaufen, die Dienstleistung zu buchen oder weitere Informationen anzufragen.
Genau wie Überschriften haben auch Beschreibungen eine maximale Zeichenanzahl, allerdings sind sie länger als Überschriften. Du kannst maximal 90 Zeichen verwenden und hast mehr Raum, um kreativ zu sein.
  Verwende die informelle Ansprache “Du”, wenn der Nutzer im Text direkt angesprochen wird`,
          userContent: `Erstelle überzeugend Google Ads Beschreibungen für das folgende Produkt auf Deutsch.
  Beschreibungen bestehen normalerweise aus ein oder zwei ganzen Sätzen und folgen häufig der folgenden Struktur: Unser Produkt /unsere Dienstleistung ist (ein Produkt / eine Dienstleistung), die dir (dem Kunden) bei (einem Problem) mit (einer Lösung) hilft.
  Eine weitere mögliche Struktur für Beschreibungen ist es, Nutzern zuerst das Problem vorzustellen, das sie möglicherweise erfahren, weil sie das Produkt noch nicht nutzen und dann die Lösung und wie es ihnen ergeht, wenn das Problem gelöst ist.
Beschreibungen können auch verwendet werden, um aufzuzeigen, wie einfach es ist in Produkt zu verwenden oder eine Dienstleistung zu nutzen.
   Dein Output sollt aus 5 Beschreibungen mit minimal 65 und maximal 90 Zeichen bestehen, die alle unabhängig voneinander das Produkt oder die Dienstleistung bewerben. Numeriere die einzelnen ergebnisse nicht und verwende keine Zeilenumbrüche in den Beschreibungen.
  Die einzelnen Beschreibungen werden durch einen vertikalen Trennstrich ("|") voneinander getrennt angezeigt.
  Product Name: ${userInputProductName}
  Product Description: ${userInputProductDescription}
  Tone of Voice : ${useInputProductToneVoice}
  Ad Description`,
        });
      } else {
        completionRequest({
          userId: userId,
          systemContent: `You are an expert content writer for Google Search Ad Descriptions. 
                        You work for a company that writes search copy for clients.
                        Google Ads Descriptions complement Google Ads Headlines, which usually get the attention of the user and entices them to learn more about the product or service.
                        Google Ads descriptions then provide the user with more information about the product or service in a manner whcih ultimately convinces them to click on the ad.
                        Like headlines, descriptions have a character limit though they are longer than headlines (90 characters), so you have more room to be creative.`,

          userContent: `Create compelling Google Ads Descriptions for the following product in English.
                      Descriptions usually consists of one or two full sentences having variants of the following structure: Our product/service is a (product/service) that (benefit) you the customer facing (problem) by (solution).
                      An additonal template for descriptions is to take users through a journey of the product or service, starting with the problem, then the solution, and finally the benefit.
                      Descriptions can also be used to drive actions showcasing things like how easy it is to get started with the product or service, or how easy it is to use the product or service.
                      Your output should consist of 5 descriptions of up to 90 characters each that promote your product or service.The descriptions are separated from each other by a vertical bar ("|"). 
                      Do not number your headline or have any punctuation at the end of your headline.
                      Do not have any carriage returns in your output.
                      Product Name: ${userInputProductName} 
                      Product Description: ${userInputProductDescription} 
                      Tone of Voice : ${useInputProductToneVoice}
                      Ad Description:`,
        });
      }
    }
  };

  useEffect(() => {
    const myObject = inputDataDetail.find((item) => item.index === index);
    setUserInputProductName(myObject?.pName);
    setUserInputProductDescription(myObject?.pDesc);
    setUseInputProductToneVoice(myObject?.toneVoiceDesc);
  }, [inputDataDetail]);

  const countDescription = (item) => {
    let filteredDescription = [];
    let filteredDescription2 = [];
    let notEmpty;
    let notEmpty2;
    for (let i = 0; i < Object?.keys(item)?.length; i++) {
      const description = item[`Description ${i}`];
      const position = item[`Description ${i} position`];
      filteredDescription.push(item[`Description ${i}`]);
      filteredDescription2.push({ description, position });
    }
    if (filteredDescription?.length) {
      notEmpty = filteredDescription?.filter((el) => {
        if (!el || el == " --" || el == "-- " || el == "--") {
          return null;
        } else {
          return el;
        }
      });
    }
    if (filteredDescription2?.length) {
      notEmpty2 = filteredDescription2.filter(
        (desc) =>
          desc?.description &&
          desc?.description?.trim().length > 0 &&
          desc?.description?.trim() !== "--"
      );
    }
    console.log("notEmpty2", notEmpty2);
    return notEmpty2;
  };

  const [arrayObj, setArrayObj] = useState(localData ? localData[index] : []);

  useEffect(() => {
    if (data.isSuccess) {
      const updatedObject = { ...arrayObj };

      // Create a new array of description
      data.data.message.map((description, index) => {
        //Update the newly generated description as key value pairs in the object
        updatedObject[
          `Description ${countDescription(item)?.length + index + 1}`
        ] = description;
        updatedObject[
          `Description ${countDescription(item)?.length + index + 1} position`
        ] = " --";
        return updatedObject;
      });
      //Update the state with the new object
      setArrayObj(updatedObject);
    }
  }, [data.isSuccess, countDescription(item)?.length]);

  useEffect(() => {
    var nArr = [...localData];
    nArr[index] = arrayObj;
    const myObject = inputDataDetail.find((item) => item.index === index);
    if (data.isSuccess) {
      setTimeout(() => {
        dispatch(setLocalData(nArr));
        const updatedObject = {
          index,
          pName: userInputProductName,
          pDesc: userInputProductDescription,
          toneVoiceDesc: useInputProductToneVoice,
          urlDesc: myObject ? myObject?.urlDesc : "",
        };
        const updatedArray = inputDataDetail.map((object) => {
          if (object.index === index) {
            return updatedObject;
          } else {
            return object;
          }
        });
        if (!updatedArray.some((object) => object.index === index)) {
          updatedArray.push(updatedObject);
        }
        dispatch(setInputDataDetail(updatedArray));
      }, 2000);
    }
  }, [arrayObj]);

  const [filteredValue, setFilteredValue] = useState();

  useEffect(() => {
    if (selectedDescriptionVal) {
      setFilteredValue(
        Object.entries(item).filter(
          (value) => value[1] === selectedDescriptionVal
        )
      );
    }
  }, [selectedDescriptionVal]);

  const updateSelectedDescriptions = (Description) => {
    if (selectedDescriptions?.[`row${index}`]) {
      // check if value exist by index, then remove it either add it
      let foundedIndex = selectedDescriptions?.[`row${index}`]?.findIndex(
        (item) => item?.Description == Description
      );
      if (foundedIndex > -1) {
        let an_array = selectedDescriptions?.[`row${index}`];

        an_array?.splice(foundedIndex, 1);

        selectedDescriptions[`row${index}`] = an_array;
      } else {
        console.log("the_data_des", selectedDescriptions);
        if (selectedDescriptions?.[`row${index}`]?.length >= 4) {
          return toast.error(
            "You are trying to select more than 4 descriptions. Please uncheck existing descriptions before selecting new description to add to your ad group"
          );
        }
        selectedDescriptions[`row${index}`]?.push({ Description });
      }
    } else {
      selectedDescriptions[`row${index}`] = [{ Description }];
    }
    setselectedDescriptions({ ...selectedDescriptions });
  };

  function getKeyByValue(object, value) {
    console.log("the_key", object, value);
    let keyfounded = "";
    Object.keys(object).find((key) => {
      if (object[key]?.trim() === value?.trim()) {
        keyfounded = key;
      }
    });
    return keyfounded;
  }

  useEffect(() => {
    if (updatedDescriptionVal !== selectedDescriptionVal) {
      let founded_key = getKeyByValue(localData[index], selectedDescriptionVal);
      console.log("founded_key", founded_key);
      if (founded_key) {
        var mArr = [...localData];
        mArr[index] = {
          ...mArr[index],
          ...{ [founded_key]: updatedDescriptionVal },
        };
        dispatch(setLocalData(mArr));
      }

      let the_key = getKeyByValue(csvReadedData[index], selectedDescriptionVal);
      if (the_key) {
        let mod_csvReadedData = [...csvReadedData];
        mod_csvReadedData[index] = {
          ...mod_csvReadedData[index],
          ...{ [the_key]: updatedDescriptionVal },
        };
        console.log("the_key_2", mod_csvReadedData);
        dispatch(setcsvReadedData(mod_csvReadedData));
      }
    }
  }, [updatedDescriptionVal]);

  const DescriptionRow = ({ val, ind }) => {
    const descriptionPositionList = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "No Position", value: "--" },
    ];

    const handlePosition = (e, headlineVal) => {
      var mArr = [...localData];
      const updatedAdObject = { ...mArr[index] };
      const matchingHeadlineKey = Object.keys(updatedAdObject).find(
        (key) => updatedAdObject[key] === headlineVal
      );

      if (matchingHeadlineKey && matchingHeadlineKey.includes("Description")) {
        const headlineNumber = matchingHeadlineKey.match(/\d+/)[0];
        updatedAdObject[`Description ${headlineNumber} position`] = e.value;
      }

      var nArr = [...localData];
      nArr[index] = updatedAdObject;
      dispatch(setLocalData(nArr));
    };

    return (
      <div key={ind} className="exp-row flex items-center">
        <div className="exp-row-item flex items-center">
          <div className="mr-2">{ind + 1}</div>
          <input
            onChange={(e) => {
              updateSelectedDescriptions(val.description);
            }}
            checked={
              selectedDescriptions?.[`row${index}`]?.findIndex(
                (item) => item?.Description == val.description
              ) > -1
                ? true
                : false
            }
            type="checkbox"
            className="check-box w-5 h-5"
          />
          <div className="item-status mx-4 b5 w-[150px] text-center">
            {mod_localDataDescriptionkeys?.includes(val.description)
              ? "Uploaded"
              : "AI-Generated"}
          </div>
        </div>
        <div className="exp-row-item flex items-center justify-center">
          {val.description === "---" ? null : (
            <Tooltip
              title="Character count"
              position="top"
              arrow="true"
              animation="scale"
            >
              <button className="btn button" disabled={true}>
                {val?.description.trim().length}
              </button>
            </Tooltip>
          )}
        </div>
        <div className="exp-row-item flex items-center">
          <div className="exp-row-tag">
            {val?.description ? val?.description : "--"}
          </div>
        </div>
        <div className="exp-row-item flex items-center">
          <div className="exp-row-tag">
            <Tooltip
              title="Set Description Postions"
              position="top"
              arrow="true"
              animation="scale"
            >
              <DropDown
                headlinesPositionList={descriptionPositionList}
                handlePosition={handlePosition}
                headlineVal={val?.description.trim()}
                headlinePositionVal={val?.position}
              />
            </Tooltip>
          </div>
        </div>
        <div className="exp-row-item flex items-center ml-3">
          <div
            className="edit-icon flex items-center justify-center cursor-pointer"
            onClick={(e) => {
              setFilteredValue(
                Object.entries(item).filter(
                  (value) => value[1] == val?.description?.trim()
                )
              );

              setOpenUpdateDescription(true);
              setSelectedDescriptionVal(val?.description?.trim());
              setUpdatedDescriptionVal(val?.description?.trim());
            }}
          >
            <Tooltip
              title="Update Description"
              position="top"
              arrow="true"
              animation="scale"
            >
              <EditIcon />
            </Tooltip>
          </div>
        </div>

        {/* <div className="exp-row-item flex items-center justify-center">
        <div className="btn button">New Variants</div>
      </div>
      <div className="exp-row-item flex items-center justify-center">
        <input
          type="text"
          className="txt-seach-key"
          placeholder="Include Keywords"
        />
      </div>
      <div className="exp-row-item flex items-center justify-center">
        <Select
          defaultValue={selectedStyle}
          value={selectedStyle}
          onChange={setSelectedStyle}
          getOptionLabel={(option) => option.display}
          getOptionValue={(option) => option.value}
          options={StyleList}
          className="select"
          id="adGroup"
          name="adGroup"
          placeholder="Select Ad-Group"
        />
      </div> */}
      </div>
    );
  };

  return (
    <div key={index} className="row-expend-block flex flex-col">
      <div className="expend-row-headline flex items-center flex-col">
        <div className="expend-row-headline-top flex">
          <div className="input-field flex flex-col w-2/5">
            <div className="input-tag text-sm font-semibold">
              Product/Service Name
            </div>
            <div className="input-tag mb-2 text-sm font-semibold text-red-700">
              Enter Name Below
            </div>
            <textarea
              name="pName"
              type="text"
              className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
              placeholder="Product/Service Name"
              value={userInputProductName}
              onChange={(e) => {
                setUserInputProductName(e.target.value);
              }}
              onBlur={handleInput}
            />
          </div>
          <div className="input-field flex flex-col w-full">
            <div className="input-tag text-sm font-semibold">
              Product/Service Summary
            </div>
            <div
              className="input-tag flex mb-2 text-sm font-semibold text-red-700 cursor-pointer"
              onClick={(e) => {
                setOpenGenerateUrl(true);
                setModelData({ item, index });
              }}
            >
              Click Cloud Icon to generate a summary of your landing page{" "}
              <div className="icon cursor-pointer ml-2 h-2 w-2">
                <CloudIcon />
              </div>
            </div>
            <textarea
              type="text"
              className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
              placeholder="Product/Service Summary"
              value={userInputProductDescription}
              onChange={(e) => setUserInputProductDescription(e.target.value)}
            />
          </div>
          <input
            type="submit"
            value={data.isLoading ? "Processing..." : "Generate Ideas"}
            className="btn button h-12 mt-8"
            onClick={(e) => submitCompletionRequestDescription(e)}
          />
        </div>

        <div className="expend-row-headline-top flex mt-5">
          <div className="input-field flex flex-col w-full">
            <div className="input-tag text-sm font-semibold">
              Product/ Tone of Voice
            </div>
            <div
              className="input-tag flex mb-2 text-sm font-semibold text-red-700 cursor-pointer"
              onClick={(e) => setOpenGenerateUrl(true)}
            >
              Click Cloud Icon to generate a tune of voice of your landing page
              <div className="icon cursor-pointer ml-2 h-2 w-2">
                <CloudIcon />
              </div>
            </div>
            <textarea
              type="text"
              className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
              placeholder="Product / Tune of voice"
              value={useInputProductToneVoice}
              onChange={(e) => setUseInputProductToneVoice(e.target.value)}
            />
          </div>
        </div>

        <div className="expend-tag">Description</div>
      </div>
      <div className="expend-table-section flex flex-col">
        {data?.isLoading ? (
          <AiLoader />
        ) : (
          <>
            {countDescription(item)?.length ? (
              <>
                {countDescription(item)?.map((val, ind) => (
                  <DescriptionRow val={val} ind={ind} />
                ))}
              </>
            ) : null}
          </>
        )}
      </div>
      <Modal
        open={openUpdateDescription}
        onClose={() => setOpenUpdateDescription(false)}
      >
        <UpdateDescriptionModel
          setOpen={setOpenUpdateDescription}
          updatedDescriptionVal={updatedDescriptionVal}
          setUpdatedDescriptionVal={setUpdatedDescriptionVal}
        />
      </Modal>
    </div>
  );
};

export default RowExpendDescription;
