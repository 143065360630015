import React, { useState } from "react";
import Modal from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import WoRowExpendHeadline from "../WoFileDataTable/WoRowExpendHeadline";
import WoRowExpendDescription from "../WoFileDataTable/WoRowExpendDescription";
import GenerateUrlSummaryWoF from "../GenerateUrlSummaryModel/GenerateUrlSummaryWoF";
import EditRow from "./EditRow";

import {
  CloudIcon,
  DetailIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  EditIcon,
} from "../../assets/Icons";

import {
  setExpendWoRow,
  setSelectedWoType,
} from "../../store/reducers/woFileReducer";

const WoFileTableRow = ({ item, index }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [modelData, setModelData] = useState({ item, index });
  const [rowModelData, setRowModelData] = useState();
  const [openGenerateUrl, setOpenGenerateUrl] = useState(false);
  const { expendWoRow, selectedWoType } = useSelector(
    (state) => state.woFileReducer
  );
  const [openUpdateHeadline, setOpenUpdateHeadline] = useState();
  return (
    <>
      <div
        key={index}
        className={`main-table-row flex flex-col ${
          expendWoRow.status && expendWoRow.index === index ? "expend" : ""
        } `}
      >
        <div className={`tbl-row flex items-center cursor-pointer `}>
          <div className="row-item flex items-start justify-center flex-col">
            <div className="flex flex-col items-center">
              <h1 className="text-black font-semibold text-lg mb-3">
                Campaign
              </h1>
              <h1 className="tag text-black">
                {item?.campaign ? (
                  item?.campaign
                ) : (
                  <div
                    id="edit-row-icon"
                    className="Edit Campaign Properties"
                    onClick={(e) => {
                      setOpenEditRow(true);
                      setModelData({ item, index });
                    }}
                  >
                    <Tooltip
                      title="Edit Campaign Properties"
                      position="top"
                      arrow="true"
                      animation="scale"
                    >
                      <EditIcon />
                    </Tooltip>
                  </div>
                )}
              </h1>
            </div>
          </div>
          <div className="row-item flex items-start justify-center flex-col">
            <div className="flex flex-col items-center">
              <h1 className="text-black font-semibold text-lg mb-3">
                Ad Group
              </h1>
              <h1 className="tag  text-black">
                {item?.adGroup ? (
                  item?.adGroup
                ) : (
                  <div
                    id="edit-row-icon"
                    className="Edit Campaign Properties"
                    onClick={(e) => {
                      setOpenEditRow(true);
                      setModelData({ item, index });
                    }}
                  >
                    <Tooltip
                      title="Edit Campaign Properties"
                      position="top"
                      arrow="true"
                      animation="scale"
                    >
                      <EditIcon />
                    </Tooltip>
                  </div>
                )}
              </h1>
            </div>
          </div>
          <div className="row-item flex flex-col items-start">
            <div className="item flex items-center">
              <button
                onClick={(e) => {
                  dispatch(
                    setSelectedWoType({
                      lbl: "headline",
                      index: index,
                    })
                  );
                }}
                className={`cleanbtn radio-btn rel ${
                  (selectedWoType.lbl === "headline") &
                  (selectedWoType.index === index)
                    ? "on"
                    : ""
                }`}
              />
              <div className="toggle-desc ml-2">
                <div className="mr-2">{item.headlines?.length}</div>
                {item.headlines?.length > 1 ? "Headlines" : "Headline"}
              </div>
            </div>
            <div className="item flex items-center">
              <button
                onClick={(e) => {
                  dispatch(
                    setSelectedWoType({
                      lbl: "description",
                      index: index,
                    })
                  );
                }}
                className={`cleanbtn radio-btn rel ${
                  selectedWoType.lbl === "description" &&
                  selectedWoType.index === index
                    ? "on"
                    : ""
                }`}
              />
              <div className="toggle-desc ml-2">
                <div className="mr-2">{item.descriptions?.length}</div>
                {item.descriptions?.length > 1 ? "Descriptions" : "Description"}
              </div>
            </div>
          </div>
          <div className="row-item flex items-center justify-end">
            <div className="icons-actions flex items-center">
              <div
                className="cloud-icon icon flex items-center justify-center relative"
                onClick={(e) => {
                  setOpenGenerateUrl(true);
                  setModelData({ item, index });
                }}
              >
                {/* <Tooltip
                  title="Generate Description from Website"
                  position="top"
                  arrow="true"
                  animation="scale"
                >
                  <CloudIcon />
                </Tooltip> */}
              </div>

              <div
                id="edit-row-icon"
                className="Edit Campaign Properties"
                onClick={(e) => {
                  setOpenEditRow(true);
                  setModelData({ item, index });
                }}
              >
                <Tooltip
                  title="Edit Campaign Properties"
                  position="top"
                  arrow="true"
                  animation="scale"
                >
                  <EditIcon />
                </Tooltip>
              </div>

              {/* <div
                id="detail-icon"
                className="detail-icon icon flex items-center justify-center"
                onClick={(e) => {
                  setOpen(true);
                  setModelData({ item, index });
                }}
              >
                <DetailIcon />
              </div> */}
              <Tooltip
                title="Expand"
                position="top"
                arrow="true"
                animation="scale"
              >
                <div
                  className="arrow-icon icon flex items-center justify-center"
                  onClick={(e) => {
                    if (
                      expendWoRow?.status === true &&
                      expendWoRow?.index === index
                    ) {
                      dispatch(
                        setExpendWoRow({
                          status: false,
                          index: "",
                        })
                      );
                    } else {
                      dispatch(
                        setExpendWoRow({
                          status: true,
                          index: index,
                        })
                      );
                      dispatch(
                        setSelectedWoType({
                          lbl: "headline",
                          index: index,
                        })
                      );
                    }
                  }}
                >
                  {expendWoRow.status && expendWoRow.index === index ? (
                    <ArrowUpIcon />
                  ) : (
                    <ArrowDownIcon />
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        {expendWoRow.status && expendWoRow.index === index && (
          <>
            {(selectedWoType.lbl === "headline") &
            (selectedWoType.index == index) ? (
              <>
                <WoRowExpendHeadline
                  item={item.headlines}
                  index={index}
                  setExpendWoRow={setExpendWoRow}
                  setOpenGenerateUrl={setOpenGenerateUrl}
                />
              </>
            ) : (selectedWoType.lbl === "description") &
              (selectedWoType.index == index) ? (
              <>
                {/* {item.descriptions?.length ? ( */}
                <WoRowExpendDescription
                  item={item.descriptions}
                  index={index}
                  setExpendWoRow={setExpendWoRow}
                  setOpenGenerateUrl={setOpenGenerateUrl}
                />
                {/* ) : null} */}
              </>
            ) : null}
          </>
        )}
      </div>

      <Modal open={openEditRow} onClose={() => setOpenEditRow(false)}>
        <EditRow setOpenEditRow={setOpenEditRow} modelData={modelData} />
      </Modal>
      <Modal open={openGenerateUrl} onClose={() => setOpenGenerateUrl(false)}>
        <GenerateUrlSummaryWoF
          setOpenGenerateUrl={setOpenGenerateUrl}
          modelData={modelData}
        />
      </Modal>
    </>
  );
};

export default WoFileTableRow;
