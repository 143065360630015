import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../../assets/Icons";

const UpdateWoHeadline = ({
  setOpen,
  updatedHeadlineVal,
  setUpdatedHeadlineVal,
  editTitleinWoFileJSONDownload,
  val
}) => {
  const [textValue, setTextValue] = useState(updatedHeadlineVal);
  const handleInput = (e) => {
    setTextValue(e.target.value);
  };
  const submit = () => {
    //editTitleinWoFileJSONDownload(textValue,val)
    setUpdatedHeadlineVal(textValue);
    setOpen(false);
  };
  return (
    <div className="update-headline-model flex">
      <div className="update-headline-m-block flex flex-col w-full">
        <div className="model-hdr flex items-center justify-end mb-8">
          <div
            className="cross-icon flex items-center justify-center cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="field flex flex-col">
          <div className="lbl">Edit Headline</div>
          {textValue.length === 30 && (
            <p className="alert-danger">
              This Headline exceeds the character limitations.
            </p>
          )}
          <textarea
            type="text"
            className="txt min-h-[200px]"
            value={textValue}
            onChange={(e) => {
              handleInput(e);
            }}
            maxLength="30"
          />
        </div>
        <div className="field flex flex-col">
          <button className="btn button" onClick={(e) => submit()}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateWoHeadline;
