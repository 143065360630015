import { useState, useEffect } from "react";
import { useForgetPasswordMutation } from "../../store/services/authService";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    address: {
      email: "",
    },
  });
  const handleInputs = (e) => {
    setState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        email: e.target.value,
      },
    }));
  };
  const [forget, response] = useForgetPasswordMutation();

  const errors = response?.error?.data?.errors
    ? response?.error?.data?.errors
    : [];
  const userForgetFunction = (e) => {
    e.preventDefault();
    forget({ state });
  };

  useEffect(() => {
    if (response.isSuccess) {
      toast.success(`${response?.data?.message}`);
      navigate("/auth/login");
    }
  }, [response.isSuccess]);

  return (
    <div className="bg-themeColor h-screen flex justify-center items-center px-4">
      <form
        className="inner-box flex flex-col bg-white p-5 w-[500px] sm:[300px] md:[500px] lg:[500px] rounded"
        onSubmit={userForgetFunction}
      >
        <h3 className="mb-4 text-black capitalize font font-bold text-lg">
          Reset Password
        </h3>
        {errors.length > 0 &&
          errors.map((error, key) => (
            <div key={key}>
              <p className="alert-danger">{error.msg}</p>
            </div>
          ))}
        <div className="mb-4 mt-4">
          <input
            type="email"
            name="email"
            onChange={handleInputs}
            value={state.email}
            placeholder="Enter email..."
            className="w-full font-medium text-lg bg-[#eee] p-2 rounded outline-none text-black1"
          />
        </div>

        <div className="mb-4">
          <input
            type="submit"
            value={response.isLoading ? "Loading..." : "Request Reset Password"}
            className="bg-[#133250] w-full p-4 rounded text-white uppercase font-semibold cursor-pointer"
          />
        </div>

        <div className="mb-4 flex items-center justify-end w-full">
          <Link
            to="/auth/login"
            className="text-black capitalize font font-medium text-lg cursor-pointer hover:underline"
          >
            Login!
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ForgetPassword;
