import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { EditIcon, CloudIcon, EyeOffIcon, EyeOnIcon } from "../../assets/Icons";
import Modal from "../Modal";
import AiLoader from "../AiLoader";

import {
  setFormInfo,
  setGlobalData,
  setLocalData,
  deleteLocalData,
  setcsvReadedData,
} from "../../store/reducers/globalReducer";
import {
  useOpenAiCompletionRequestHeadlineArrayMutation,
  useOpenAiCompletionRequestArrayMutation,
  useOpenAiGenerateCategoryMutation,
} from "../../store/services/openAiService";
import { useOpenAi4HeadlineArrayMutation } from "../../store/services/openAi4Service";
import UpdateHeadlineModel from "../UpdateHeadlineModel";
import Spinner from "../Spinner";
import { fromJSON } from "postcss";

const GlobalHeadline = ({ item, setOpenGenerateUrl, setModelData }) => {
  const dispatch = useDispatch();

  const { globalData, localData, formInfo, lang } = useSelector(
    (state) => state.globalReducer
  );
  const { userId } = useSelector((state) => state.authReducer);

  const [completionRequest, data] = useOpenAi4HeadlineArrayMutation();

  const [selectedHeadlinesArray, setSelectedHeadlinesArray] = useState([]);
  const [openUpdateHeadline, setOpenUpdateHeadline] = useState(false);
  const [updatedHeadlineVal, setUpdatedHeadlineVal] = useState();
  const [updatedHeadlineIndex, setUpdatedHeadlineIndex] = useState();
  const [userInputProductName, setUserInputProductName] = useState(
    formInfo.pName ? formInfo.pName : ""
  );
  const [userInputProductDescription, setUserInputProductDescription] =
    useState(formInfo.pDesc ? formInfo.pDesc : "");

  const [useInputProductToneVoice, setUseInputProductToneVoice] = useState(
    formInfo.toneVoiceDesc ? formInfo.toneVoiceDesc : ""
  );

  const submitCompletionRequestHeadline = () => {
    if (userInputProductName === "") {
      toast.error("Enter Product Name!");
    } else if (userInputProductDescription === "") {
      toast.error("Enter Product Description!");
    } else {
      if (lang.value === "german") {
        completionRequest({
          userId: userId,
          systemContent: "text-davinci-003",
          userContent: `
          Erstelle eine überzeugende Google Ads Überschrift für das folgende Produkt.
          **Nutzer nehmen vor allem die Texte in den Überschriften zur Kenntnis, sie sollten darum wenn möglich die Worte enthalten, die die Nutzer auch in die Google Suche eingegeben haben. Deine Anzeige besteht aus 3 Überschriften mit jeweils bis zu 30 Zeichen, in denen dein Produkt oder deine Dienstleistung beworben wird. Die Überschriften werden durch einen vertikalen Strich ("|") voneinander getrennt dargestellt.**
          Product Name: ${userInputProductName}
          Product Description: ${userInputProductDescription}
          Tone of Voice : ${useInputProductToneVoice}
          Ad Headline:
          `,
        });
      } else if (lang.value === "germanF") {
        completionRequest({
          userId: userId,
          systemContent: `Du bist professioneller Texter für Google Ads Überschriften.
          Du arbeitest für eine Firma, die Anzeigentexte für Google Ads Werbekunden schreibt.
          Überschriften in Suchanzeigen müssen eine Menge Informationen übermitteln und ein positives Gefühl über ein Produkt oder eine Dienstleistung in weniger als 30 Zeichen übermitteln.
          Überschriften sind kurz, ausdrucksstark, bringen den Inhalt auf den Punkt und erregen die Aufmerksamkeit potenzieller Kunden.
          Überschrifen sollen eine anziehende Wirkung auf die Zielgruppe ausüben, damit die Nutzer den Rest der Anzeige durchlesen und davon überzeugt werden, auf die Website durchzuklicken.
          Verwende die formelle Ansprache “Sie”, wenn der Nutzer im Text direkt angesprochen wird`,
          userContent: `Erstelle überzeugende Google Ads Überschriften für das folgende Produkt auf Deutsch.
          Der Output sollten 10 Überschriften mit maximal 30 Zeichen sein, die alle das Produkt oder die Dienstleistung bewerben.
          Die erstellten Headlines sollten divers sein, dafür sollten sie unterschiedliche Kategorien enthalten: Handlungsaufforderungen, Produkteigenschaften, Social Proof, Produktvorteile, Problemaussagen von Kunden und Sonderangebote oder Preisinformationen.
          Numeriere die Überschriften nicht und nutze keine Satzzeichen am Ende der Überschrift.
         Die einzelnen Überschriften werden durch einen vertikalen Trennstrich ("|") voneinander getrennt angezeigt.
         Product Name: ${userInputProductName}
         Product Description: ${userInputProductDescription}
         Tone of Voice : ${useInputProductToneVoice}
         Ad Headlines:`,
        });
      } else if (lang.value === "germanInF") {
        completionRequest({
          userId: userId,
          systemContent: `Du bist professioneller Texter für Google Ads Überschriften.
          Du arbeitest für eine Firma, die Anzeigentexte für Google Ads Werbekunden schreibt.
          Überschriften in Suchanzeigen müssen eine Menge Informationen übermitteln und ein positives Gefühl über ein Produkt oder eine Dienstleistung in weniger als 30 Zeichen übermitteln.
          Überschriften sind kurz, ausdrucksstark, bringen den Inhalt auf den Punkt und erregen die Aufmerksamkeit potenzieller Kunden.
          Überschrifen sollen eine anziehende Wirkung auf die Zielgruppe ausüben, damit die Nutzer den Rest der Anzeige durchlesen und davon überzeugt werden, auf die Website durchzuklicken.
          Verwende die informelle Ansprache “Du”, wenn der Nutzer im Text direkt angesprochen wird`,
          userContent: `Erstelle überzeugende Google Ads Überschriften für das folgende Produkt auf Deutsch.
          Der Output sollten 10 Überschriften mit maximal 30 Zeichen sein, die alle das Produkt oder die Dienstleistung bewerben.
          Die erstellten Headlines sollten divers sein, dafür sollten sie unterschiedliche Kategorien enthalten: Handlungsaufforderungen, Produkteigenschaften, Social Proof, Produktvorteile, Problemaussagen von Kunden und Sonderangebote oder Preisinformationen.
          Numeriere die Überschriften nicht und nutze keine Satzzeichen am Ende der Überschrift.
         Die einzelnen Überschriften werden durch einen vertikalen Trennstrich ("|") voneinander getrennt angezeigt.
         Product Name: ${userInputProductName}
         Product Description: ${userInputProductDescription}
         Tone of Voice : ${useInputProductToneVoice}
         Ad Headlines:`,
        });
      } else {
        completionRequest({
          userId: userId,
          systemContent: `You are an expert content writer for Google Search Ad Headlines. 
          You work for a company that writes search copy for clients.
          Search Ad Headlines have to convey a lot of information and also trigger positive emotions about the product or service within 30 characters.
          Headlines are designed to be short, punchy, and to catch the attention of your potential customers. 
          They should entice your audience and convince them to read the rest of your ad and ultimately click on it.
          `,
          userContent: `Create compelling Google Ads Headlines for the following product in English.
          Your output should consist of 10 headlines of up to 30 characters each that promote your product or service.
          Have a diversity of headline types in your output, including:Calls to Action, Highlighting Customer Problems, Features, Benefits, and Emotions.
          Do not number your headline or have any punctuation at the end of your headline.
          The headings are separated from each other by a vertical bar ("|"). 
         Product Name: ${userInputProductName} 
         Product Description: ${userInputProductDescription} 
         Tone of Voice : ${useInputProductToneVoice}
         Ad Headlines:`,
        });
      }
    }
  };

  useEffect(() => {
    if (data.isSuccess) {
      const updatedHeadlines = [
        ...globalData?.headlines,
        ...data?.data?.message,
      ];
      const updatedObject = {
        pName: userInputProductName,
        pDesc: userInputProductDescription,
        toneVoiceDesc: useInputProductToneVoice,
      };
      dispatch(setFormInfo(updatedObject));
      dispatch(setGlobalData({ ...globalData, headlines: updatedHeadlines }));
    }
  }, [data.isSuccess]);

  useEffect(() => {
    const oldHeadlines = [...globalData?.headlines];
    if (updatedHeadlineIndex !== -1) {
      oldHeadlines[updatedHeadlineIndex] = updatedHeadlineVal;
      dispatch(
        setGlobalData({
          ...globalData,
          headlines: oldHeadlines,
        })
      );
    }
  }, [updatedHeadlineVal]);

  useEffect(() => {
    setUserInputProductName(formInfo.pName ? formInfo.pName : "");
    setUserInputProductDescription(formInfo.pDesc ? formInfo.pDesc : "");
    setUseInputProductToneVoice(
      formInfo.toneVoiceDesc ? formInfo.toneVoiceDesc : ""
    );
  }, [formInfo]);

  const handleHeadlinesArray = (e, item) => {
    // Destructuring
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      selectedHeadlinesArray.push(item.trim());
    }
    // Case 2  : The user unchecked the box
    else {
      let newSelectedHeadlinesArray = selectedHeadlinesArray.filter(function (
        filterItem
      ) {
        return filterItem.trim() !== item.trim();
      });
      setSelectedHeadlinesArray(newSelectedHeadlinesArray);
    }
  };

  const PostHeadlines = () => {
    if (selectedHeadlinesArray.length) {
      const updatedOldArray = localData.map((item, index) => {
        let newRow = { ...item };
        const keys = Object.keys(newRow);
        const count = keys.length;
        for (let i = 0; i < selectedHeadlinesArray.length; i++) {
          let headlineNum = count + i;
          newRow[`Headline ${headlineNum}`] = selectedHeadlinesArray[i];
        }
        return newRow;
      });
      dispatch(setLocalData(updatedOldArray));
      toast.success("Headlines added successfully in all rows!");
    }
  };

  return (
    <div className="global-row-expend-block flex flex-col">
      <div className="expend-row-headline flex flex-col items-center">
        <div className="expend-row-headline-top flex">
          <div className="input-field flex flex-col w-2/5">
            <div className="input-tag text-sm font-semibold">
              Product/Service Name
            </div>
            <div className="input-tag mb-2 text-sm font-semibold text-red-700">
              Enter Name Below
            </div>
            <textarea
              id="pName"
              type="text"
              className="txt-seach-key min-h-[100px] max-h-[100px] overflow-hidden"
              placeholder="Product/Service Name"
              value={userInputProductName}
              onChange={(e) => setUserInputProductName(e.target.value)}
            />
          </div>
          <div className="input-field flex flex-col w-full">
            <div className="input-tag text-sm font-semibold">
              Product/Service Summary
            </div>
            <div
              className="input-tag flex mb-2 text-sm font-semibold text-red-700 cursor-pointer"
              onClick={(e) => {
                setOpenGenerateUrl(true);
                setModelData({ item: "", index: 1190 });
              }}
            >
              Click Cloud Icon to generate a summary of your landing page{" "}
              <div className="icon cursor-pointer ml-2 h-2 w-2">
                <CloudIcon />
              </div>
            </div>
            <textarea
              type="text"
              className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
              placeholder="Product/Service Summary"
              value={userInputProductDescription}
              onChange={(e) => setUserInputProductDescription(e.target.value)}
            />
          </div>
          <input
            type="submit"
            value={data.isLoading ? "Processing..." : "Generate Ideas"}
            className="btn button h-12 mt-8"
            onClick={(e) => submitCompletionRequestHeadline(e)}
          />
        </div>

        <div className="expend-row-headline-top flex mt-5">
          <div className="input-field flex flex-col w-full">
            <div className="input-tag text-sm font-semibold">
              Product/ Tone of Voice
            </div>
            <div
              className="input-tag flex mb-2 text-sm font-semibold text-red-700 cursor-pointer"
              onClick={(e) => setOpenGenerateUrl(true)}
            >
              Click Cloud Icon to generate a tune of voice of your landing page
              <div className="icon cursor-pointer ml-2 h-2 w-2">
                <CloudIcon />
              </div>
            </div>
            <textarea
              type="text"
              className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
              placeholder="Product / Tune of voice"
              value={useInputProductToneVoice}
              onChange={(e) => setUseInputProductToneVoice(e.target.value)}
            />
          </div>
        </div>

        <div className="flex items-center w-full">
          <div className="expend-tag flex flex-1 text-center items-center justify-center">
            Global Headlines
          </div>
          <div className="btn button" onClick={(e) => PostHeadlines()}>
            Post to All Ads
          </div>
        </div>
      </div>
      <div className="expend-table-section flex flex-col">
        {data?.isLoading ? (
          <AiLoader />
        ) : (
          <>
            {globalData?.headlines?.length ? (
              <>
                {globalData?.headlines?.map((itm, idx) => (
                  <div key={idx} className="exp-row flex items-center">
                    <div className="exp-row-item flex items-center">
                      <div className="col-numb">{idx + 1}</div>
                      <div>
                        <input
                          onChange={(e) => {
                            handleHeadlinesArray(e, itm);
                          }}
                          type="checkbox"
                          className="check-box w-5 h-5"
                        />
                      </div>
                    </div>
                    <div className="exp-row-item flex items-center justify-center">
                      {itm === "---" ? null : (
                        <button className="btn button" disabled={true}>
                          {itm?.trim().length}
                        </button>
                      )}
                    </div>
                    <div className="exp-row-item flex items-center">
                      <div className="exp-row-tag">{itm ? itm : "--"}</div>
                    </div>
                    <div className="exp-row-item flex items-center justify-end">
                      <div
                        className="edit-icon flex items-center justify-center cursor-pointer"
                        onClick={(e) => {
                          setOpenUpdateHeadline(true);
                          setUpdatedHeadlineVal(itm);
                          setUpdatedHeadlineIndex(idx);
                        }}
                      >
                        <EditIcon />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="expend-tag text-center font-medium text-red-700 text-lg">
                No headlines were generated. Please press “Generate Ideas” for
                more output.
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        open={openUpdateHeadline}
        onClose={() => setOpenUpdateHeadline(false)}
      >
        <UpdateHeadlineModel
          setOpen={setOpenUpdateHeadline}
          updatedHeadlineVal={updatedHeadlineVal}
          setUpdatedHeadlineVal={setUpdatedHeadlineVal}
        />
      </Modal>
    </div>
  );
};

export default GlobalHeadline;
