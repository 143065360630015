import { configureStore } from "@reduxjs/toolkit";

import authService from "./services/authService";
import openAiService from "./services/openAiService";
import openAi4Service from "./services/openAi4Service";
import userService from "./services/userService";

import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";
import woFileReducer from "./reducers/woFileReducer";

const Store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [openAiService.reducerPath]: openAiService.reducer,
    [openAi4Service.reducerPath]: openAi4Service.reducer,
    [userService.reducerPath]: userService.reducer,

    authReducer: authReducer,
    globalReducer: globalReducer,
    woFileReducer: woFileReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      openAiService.middleware,
      openAi4Service.middleware,
      userService.middleware,
    ]),
});

export default Store;
