import { useState, useEffect } from "react";
import { useAuthLoginMutation } from "../../store/services/authService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { setUserToken, setUserId } from "../../store/reducers/authReducer";
import { EyeOnIcon, EyeOffIcon } from "../../assets/Icons";
const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const [login, response] = useAuthLoginMutation();

  const errors = response?.error?.data?.errors
    ? response?.error?.data?.errors
    : [];
  const adminLoginFunction = (e) => {
    e.preventDefault();
    login({ state });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (response.isSuccess) {
      localStorage.setItem("userToken", response?.data?.token);
      localStorage.setItem("userId", response?.data?.id);
      dispatch(setUserToken(response?.data?.token));
      dispatch(setUserId(response?.data?.id));
      navigate("/dashboard/home");
    }
  }, [response.isSuccess]);
  useEffect(() => {
    if (response.isError) {
      toast.error(`Server internal error!`);
    }
  }, [response.isError]);

  return (
    <div className="bg-themeColor h-screen flex justify-center items-center px-4">
      <form
        className="inner-box flex flex-col bg-white p-5 w-[500px] sm:[300px] md:[500px] lg:[500px] rounded"
        onSubmit={adminLoginFunction}
      >
        <h3 className="mb-4 text-black capitalize font font-bold text-lg">
          User login
        </h3>
        {errors.length > 0 &&
          errors.map((error, key) => (
            <div key={key}>
              <p className="alert-danger">{error.msg}</p>
            </div>
          ))}
        <div className="mb-4 mt-4">
          <input
            type="email"
            name="email"
            onChange={handleInputs}
            value={state.email}
            placeholder="Enter email..."
            className="w-full font-medium text-lg bg-[#eee] p-2 rounded outline-none text-black1"
          />
        </div>

        <div className="mb-4 flex flex-col">
          <div className="flex items-center bg-[#eee] p-2 rounded outline-none">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              className="w-full font-medium text-lg text-black1 bg-transparent"
              onChange={handleInputs}
              value={state.password}
              placeholder="Enter password..."
            />

            <button
              className="show-icon cleanbtn flex items-center justify-center cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <EyeOffIcon /> : <EyeOnIcon />}
            </button>
          </div>
        </div>

        <div className="mb-4">
          <input
            type="submit"
            value={response.isLoading ? "Loading..." : "sign in"}
            className="bg-[#133250] w-full p-4 rounded text-white uppercase font-semibold cursor-pointer"
          />
        </div>
        <div className="mb-4 flex items-center justify-end w-full">
          <Link
            to="/auth/forget-password"
            className="text-black capitalize font font-medium text-lg cursor-pointer hover:underline"
          >
            Reset Password?
          </Link>
        </div>
        <div className="mb-4 flex items-center justify-end w-full">
          <Link
            to="/auth/register"
            className="text-black capitalize font font-medium text-lg cursor-pointer hover:underline"
          >
            Register as a New User
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
