import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
const Wrapper = ({ children }) => {
  return (
    <>
      <Header />
      <section>
        <div className="min-h-screen w-full bg-themeColor2">{children}</div>
      </section>
      <Footer />
    </>
  );
};
export default Wrapper;
