import React from "react";
import { CloseIcon } from "../../assets/Icons";

const TableRowModel = ({ setOpen, modelData }) => {
  const { item, index } = modelData;
  return (
    <div className="table-row-model flex">
      <div className="model-block flex flex-col">
        <div className="model-hdr flex items-center justify-end mb-8">
          <div
            className="cross-icon flex items-center justify-center cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        {item?.["Status"] ? (
          <div className="model-item flex justify-start items-start">
            <div className="model-tag">Ad Status</div>
            <div className="model-val">{item?.["Status"]}</div>
          </div>
        ) : null}
        {item?.["Final URL"] ? (
          <div className="model-item flex justify-start items-start">
            <div className="model-tag">URL</div>
            <div className="model-val">{item?.["Final URL"]}</div>
          </div>
        ) : null}
        {item?.["Campaign"] ? (
          <div className="model-item flex justify-start items-start">
            <div className="model-tag">Campaign</div>
            <div className="model-val">{item?.["Campaign"]}</div>
          </div>
        ) : null}
        {item?.["Path 1"] ? (
          <div className="model-item flex justify-start items-start">
            <div className="model-tag">Path 1</div>
            <div className="model-val">
              <div className="model-val">{item?.["Path 1"]}</div>
            </div>
          </div>
        ) : null}
        {item?.["Path 2"] ? (
          <div className="model-item flex justify-start items-start">
            <div className="model-tag">Path 2</div>
            <div className="model-val">
              <div className="model-val">{item?.["Path 2"]}</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TableRowModel;
