import React, { useRef, useEffect, useState } from "react";
import DemoFile from "../../assets/file/Demo.csv";
import { CloseIcon, DownloadIcon } from "../../assets/Icons";
import LoaderWhite from "../../components/LoaderWhite";

const VideoModel = ({ setOpen }) => {
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 5000);
  }, []);

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = DemoFile;
    link.download = "Demo.csv";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <div className="demo-video-model flex">
      <div className="demo-video-model-block flex flex-col">
        <div className="block-hdr flex items-center justify-end">
          <div
            className="icon flex items-center justify-center cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="video-block flex flex-col items-center justify-center">
          <div className="sec-title">Training Video</div>
          {loader ? (
            <LoaderWhite />
          ) : (
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/807593233?h=a793d8a24e"
              width="100%"
              height="100%"
              frameborder="0"
              allowfullscreen
            />
          )}
        </div>
        <div className="download-file flex items-center flex-col">
          <div className="sec-title ">
            Upload this blank template to get started, if you don’t have a
            pre-existing editor export file
          </div>
          <button className="btn-download-file button" onClick={downloadFile}>
            <div className="btn-lbl mt-1 mr-2">Download Blank Template</div>
            <DownloadIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoModel;
