import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const userService = createApi({
  reducerPath: "users",
  tagTypes: "user",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      getUserData: builder.query({
        query: (id) => {
          return {
            url: `api/user/${id}`,
            method: "GET",
          };
        },
        providesTags: ["user"],
      }),
    };
  },
});
export const { useGetUserDataQuery } = userService;
export default userService;
