import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip } from "react-tippy";
import { EditIcon, CloudIcon, EyeOffIcon, EyeOnIcon } from "../../assets/Icons";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import AiLoader from "../../components/AiLoader";
import DropDown from "../../components/DropDown";

import {
  setLocalData,
  deleteLocalData,
  setcsvReadedData,
  setInputDataDetail,
} from "../../store/reducers/globalReducer";
import {
  useOpenAiCompletionRequestHeadlineArrayMutation,
  useOpenAiCompletionRequestArrayMutation,
  useOpenAiGenerateCategoryMutation,
} from "../../store/services/openAiService";
import { useOpenAi4HeadlineArrayMutation } from "../../store/services/openAi4Service";
import UpdateHeadlineModel from "../../components/UpdateHeadlineModel";
import Spinner from "../Spinner";

const RowExpendHeadline = ({
  item,
  index,
  setExpendRow,
  setselectedHeadlines,
  selectedHeadlines,
  localDataHeadingkeys,
  setOpenGenerateUrl,
  setModelData,
}) => {
  const dispatch = useDispatch();
  // console.log("selectedHeadlines", selectedHeadlines);

  const toastObject = {
    position: "top-center",
    autoClose: 8000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
  };
  const { localData, csvReadedData, formInfo, inputDataDetail, lang } =
    useSelector((state) => state.globalReducer);
  const { userId } = useSelector((state) => state.authReducer);

  const [completionRequest, data] = useOpenAi4HeadlineArrayMutation();

  const [openUpdateHeadline, setOpenUpdateHeadline] = useState(false);
  const [selectedHeadlineVal, setSelectedHeadlineVal] = useState();
  const [updatedHeadlineVal, setUpdatedHeadlineVal] = useState();

  const categoriesList = [
    { name: "Call to Action" },
    { name: "Bran Messaging" },
    { name: "Competitive" },
    { name: "Feature" },
    { name: "Problem Statement" },
    { name: "Pricing & Deals" },
    { name: "Social Proof" },
    { name: "Benefits" },
  ];

  const [userInputProductName, setUserInputProductName] = useState();

  const [userInputProductDescription, setUserInputProductDescription] =
    useState();

  const [useInputProductToneVoice, setUseInputProductToneVoice] = useState();

  const handleInput = () => {
    setTimeout(() => {
      const updatedObject = {
        index,
        pName: userInputProductName,
        pDesc: userInputProductDescription,
        urlDesc: userInputProductDescription,
        toneVoiceDesc: useInputProductToneVoice,
      };
      const updatedArray = inputDataDetail.map((object) => {
        if (object.index === index) {
          return updatedObject;
        } else {
          return object;
        }
      });
      if (!updatedArray.some((object) => object.index === index)) {
        updatedArray.push(updatedObject);
      }
      dispatch(setInputDataDetail(updatedArray));
    }, 5000);
  };

  const submitCompletionRequestHeadline = (e) => {
    // e.preventDefault();
    if (userInputProductName === "") {
      toast.error("Enter Product Name!", toastObject);
    } else if (userInputProductDescription === "") {
      toast.error("Enter Product Description!", toastObject);
    } else {
      if (lang.value === "german") {
        completionRequest({
          userId: userId,
          systemContent: "text-davinci-003",
          userContent: `
          Erstelle eine überzeugende Google Ads Überschrift für das folgende Produkt.
          **Nutzer nehmen vor allem die Texte in den Überschriften zur Kenntnis, sie sollten darum wenn möglich die Worte enthalten, die die Nutzer auch in die Google Suche eingegeben haben. Deine Anzeige besteht aus 3 Überschriften mit jeweils bis zu 30 Zeichen, in denen dein Produkt oder deine Dienstleistung beworben wird. Die Überschriften werden durch einen vertikalen Strich ("|") voneinander getrennt dargestellt.**
          Product Name: ${userInputProductName}
          Product Description: ${userInputProductDescription}
          Tone of Voice : ${useInputProductToneVoice}
          Ad Headline:
          `,
        });
      } else if (lang.value === "germanF") {
        completionRequest({
          userId: userId,
          systemContent: `Du bist professioneller Texter für Google Ads Überschriften.
          Du arbeitest für eine Firma, die Anzeigentexte für Google Ads Werbekunden schreibt.
          Überschriften in Suchanzeigen müssen eine Menge Informationen übermitteln und ein positives Gefühl über ein Produkt oder eine Dienstleistung in weniger als 30 Zeichen übermitteln.
          Überschriften sind kurz, ausdrucksstark, bringen den Inhalt auf den Punkt und erregen die Aufmerksamkeit potenzieller Kunden.
          Überschrifen sollen eine anziehende Wirkung auf die Zielgruppe ausüben, damit die Nutzer den Rest der Anzeige durchlesen und davon überzeugt werden, auf die Website durchzuklicken.
          Verwende die formelle Ansprache “Sie”, wenn der Nutzer im Text direkt angesprochen wird`,
          userContent: `Erstelle überzeugende Google Ads Überschriften für das folgende Produkt auf Deutsch.
          Der Output sollten 10 Überschriften mit maximal 30 Zeichen sein, die alle das Produkt oder die Dienstleistung bewerben.
          Die erstellten Headlines sollten divers sein, dafür sollten sie unterschiedliche Kategorien enthalten: Handlungsaufforderungen, Produkteigenschaften, Social Proof, Produktvorteile, Problemaussagen von Kunden und Sonderangebote oder Preisinformationen.
          Numeriere die Überschriften nicht und nutze keine Satzzeichen am Ende der Überschrift.
         Die einzelnen Überschriften werden durch einen vertikalen Trennstrich ("|") voneinander getrennt angezeigt.
         Product Name: ${userInputProductName}
         Product Description: ${userInputProductDescription}
         Tone of Voice : ${useInputProductToneVoice}
         Ad Headlines:`,
        });
      } else if (lang.value === "germanInF") {
        completionRequest({
          userId: userId,
          systemContent: `Du bist professioneller Texter für Google Ads Überschriften.
          Du arbeitest für eine Firma, die Anzeigentexte für Google Ads Werbekunden schreibt.
          Überschriften in Suchanzeigen müssen eine Menge Informationen übermitteln und ein positives Gefühl über ein Produkt oder eine Dienstleistung in weniger als 30 Zeichen übermitteln.
          Überschriften sind kurz, ausdrucksstark, bringen den Inhalt auf den Punkt und erregen die Aufmerksamkeit potenzieller Kunden.
          Überschrifen sollen eine anziehende Wirkung auf die Zielgruppe ausüben, damit die Nutzer den Rest der Anzeige durchlesen und davon überzeugt werden, auf die Website durchzuklicken.
          Verwende die informelle Ansprache “Du”, wenn der Nutzer im Text direkt angesprochen wird`,
          userContent: `Erstelle überzeugende Google Ads Überschriften für das folgende Produkt auf Deutsch.
          Der Output sollten 10 Überschriften mit maximal 30 Zeichen sein, die alle das Produkt oder die Dienstleistung bewerben.
          Die erstellten Headlines sollten divers sein, dafür sollten sie unterschiedliche Kategorien enthalten: Handlungsaufforderungen, Produkteigenschaften, Social Proof, Produktvorteile, Problemaussagen von Kunden und Sonderangebote oder Preisinformationen.
          Numeriere die Überschriften nicht und nutze keine Satzzeichen am Ende der Überschrift.
         Die einzelnen Überschriften werden durch einen vertikalen Trennstrich ("|") voneinander getrennt angezeigt.
         Product Name: ${userInputProductName}
         Product Description: ${userInputProductDescription}
         Tone of Voice : ${useInputProductToneVoice}
         Ad Headlines:`,
        });
      } else {
        completionRequest({
          userId: userId,
          systemContent: `You are an expert content writer for Google Search Ad Headlines. 
          You work for a company that writes search copy for clients.
          Search Ad Headlines have to convey a lot of information and also trigger positive emotions about the product or service within 30 characters.
          Headlines are designed to be short, punchy, and to catch the attention of your potential customers. 
          They should entice your audience and convince them to read the rest of your ad and ultimately click on it.
          `,
          userContent: `Create compelling Google Ads Headlines for the following product in English.
          Your output should consist of 10 headlines of up to 30 characters each that promote your product or service.
          Have a diversity of headline types in your output, including:Calls to Action, Highlighting Customer Problems, Features, Benefits, and Emotions.
          Do not number your headline or have any punctuation at the end of your headline.
          The headings are separated from each other by a vertical bar ("|"). 
         Product Name: ${userInputProductName} 
         Product Description: ${userInputProductDescription} 
         Tone of Voice : ${useInputProductToneVoice}
         Ad Headlines:`,
        });
      }
    }
  };

  useEffect(() => {
    const myObject = inputDataDetail.find((item) => item.index === index);
    setUserInputProductName(myObject?.pName);
    setUserInputProductDescription(myObject?.pDesc);
    setUseInputProductToneVoice(myObject?.toneVoiceDesc);
  }, [inputDataDetail]);

  const countHeadlines = (item) => {
    let filteredHeadlines = [];
    let filteredHeadlines2 = [];
    let notEmpty;
    let notEmpty2;
    for (let i = 0; i < Object?.keys(item)?.length; i++) {
      const headline = item[`Headline ${i}`];
      const position = item[`Headline ${i} position`];
      filteredHeadlines.push(item[`Headline ${i}`]);
      filteredHeadlines2.push({ headline, position });
    }

    if (filteredHeadlines?.length) {
      notEmpty = filteredHeadlines?.filter((el) => {
        if (!el || el == " --") {
          return null;
        } else {
          return el;
        }
      });
    }
    if (filteredHeadlines2?.length) {
      notEmpty2 = filteredHeadlines2.filter(
        (headline) =>
          headline?.headline &&
          headline?.headline?.trim().length > 0 &&
          headline?.headline?.trim() !== "--"
      );
    }
    return notEmpty2;
  };

  const [arrayObj, setArrayObj] = useState(localData ? localData[index] : []);

  useEffect(() => {
    if (data.isSuccess) {
      const updatedObject = { ...arrayObj };

      // Create a new array of headlines
      data.data.message.map((headline, index) => {
        //Update the newly generated headlines as key value pairs in the object
        updatedObject[`Headline ${countHeadlines(item)?.length + index + 1}`] =
          headline;
        updatedObject[
          `Headline ${countHeadlines(item)?.length + index + 1} position`
        ] = " --";
        return updatedObject;
      });

      //Update the state with the new object
      setArrayObj(updatedObject);
    }
  }, [data.isSuccess, countHeadlines(item)?.length]);

  useEffect(() => {
    var nArr = [...localData];
    nArr[index] = arrayObj;
    const myObject = inputDataDetail.find((item) => item.index === index);

    if (data.isSuccess) {
      setTimeout(() => {
        dispatch(setLocalData(nArr));
        const updatedObject = {
          index,
          pName: userInputProductName,
          pDesc: userInputProductDescription,
          urlDesc: myObject ? myObject?.urlDesc : "",
          toneVoiceDesc: myObject ? myObject?.toneVoiceDesc : "",
        };
        const updatedArray = inputDataDetail.map((object) => {
          if (object.index === index) {
            return updatedObject;
          } else {
            return object;
          }
        });
        if (!updatedArray.some((object) => object.index === index)) {
          updatedArray.push(updatedObject);
        }
        dispatch(setInputDataDetail(updatedArray));
        setExpendRow(true);
      }, 2000);
    }
  }, [arrayObj]);

  const [filteredValue, setFilteredValue] = useState();

  useEffect(() => {
    setFilteredValue(
      Object.entries(item).filter((value) => value[1] === selectedHeadlineVal)
    );
  }, [selectedHeadlineVal]);

  function getKeyByValue(object, value) {
    let keyfounded = "";
    Object.keys(object).find((key) => {
      if (object[key]?.trim() === value?.trim()) {
        keyfounded = key;
      }
    });
    return keyfounded;
  }
  function getArrayIndexByValue(arr, value) {
    let indexfounded = "";
    arr.map((item, index) => {
      if (item?.Headline === value) {
        indexfounded = index;
      }
    });
    return indexfounded;
  }

  useEffect(() => {
    if (updatedHeadlineVal !== selectedHeadlineVal) {
      let founded_key = getKeyByValue(localData[index], selectedHeadlineVal);

      if (founded_key) {
        var mArr = [...localData];
        mArr[index] = {
          ...mArr[index],
          ...{ [founded_key]: updatedHeadlineVal },
        };
        dispatch(setLocalData(mArr));
      }
      let the_key = getKeyByValue(csvReadedData[index], selectedHeadlineVal);

      if (the_key) {
        let mod_csvReadedData = [...csvReadedData];
        mod_csvReadedData[index] = {
          ...mod_csvReadedData[index],
          ...{ [the_key]: updatedHeadlineVal },
        };
        // console.log("the_key_2", mod_csvReadedData);
        dispatch(setcsvReadedData(mod_csvReadedData));
      }
    }
  }, [updatedHeadlineVal]);

  const updateSelectedHeadlines = (Headline) => {
    // check if value exist by index, then remove it either add it

    if (selectedHeadlines?.[`row${index}`]) {
      let foundedIndex = selectedHeadlines?.[`row${index}`]?.findIndex(
        (item) => item?.Headline == Headline
      );
      if (foundedIndex > -1) {
        let an_array = selectedHeadlines?.[`row${index}`];

        an_array?.splice(foundedIndex, 1);

        selectedHeadlines[`row${index}`] = an_array;
      } else {
        if (selectedHeadlines?.[`row${index}`]?.length >= 15) {
          return toast.error(
            "You are trying to select more than 15 headlines. Please uncheck existing headlines before selecting new headline to add to your adg roup"
          );
        } else {
          selectedHeadlines[`row${index}`]?.push({ Headline });
        }
      }
    } else {
      selectedHeadlines[`row${index}`] = [{ Headline }];
    }
    setselectedHeadlines({ ...selectedHeadlines });
  };

  const HeadlineRow = ({ val, ind }) => {
    const [categoryRequest, categoryData] = useOpenAiGenerateCategoryMutation();
    const [categoryType, setCategoryType] = useState("");
    const submitCategoryRequestHeadline = async (val) => {
      await categoryRequest({
        prompt: `Headline: ${val}\nCategory:`,
      });
    };
    function updateData(data, value, e) {
      for (const key in data) {
        if (key.startsWith("Headline")) {
          const headlineNum = key.match(/\d+/)[0];
          if (data[key] === value.headline) {
            // data[`Headline ${headlineNum}`] = value.headline;
            data[`Headline ${headlineNum} position`] = e;

            break;
          }
        }
      }
      return data;
    }

    const headlinesPositionList = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "No Position", value: "--" },
    ];

    const handlePosition = (e, headlineVal) => {
      var mArr = [...localData];
      const updatedAdObject = { ...mArr[index] };
      const matchingHeadlineKey = Object.keys(updatedAdObject).find(
        (key) => updatedAdObject[key] === headlineVal
      );

      if (matchingHeadlineKey && matchingHeadlineKey.includes("Headline")) {
        const headlineNumber = matchingHeadlineKey.match(/\d+/)[0];
        updatedAdObject[`Headline ${headlineNumber} position`] = e.value;
      }

      var nArr = [...localData];
      nArr[index] = updatedAdObject;

      dispatch(setLocalData(nArr));
      // dispatch(setcsvReadedData(nArr));
    };

    useEffect(() => {
      setCategoryType(categoryData?.data?.category);
    }, [categoryData?.isSuccess]);

    return (
      <div key={ind} className="exp-row flex items-center">
        <div className="exp-row-item flex items-center">
          <div className="col-numb">{ind + 1}</div>
          <input
            onChange={(e) => {
              updateSelectedHeadlines(val.headline);
            }}
            checked={
              selectedHeadlines?.[`row${index}`]?.findIndex(
                (item) => item?.Headline == val.headline
              ) > -1
                ? true
                : false
            }
            type="checkbox"
            className="check-box w-5 h-5"
          />
          <div className="item-status mx-4 b5 w-[150px] text-center">
            {localDataHeadingkeys?.includes(val.headline)
              ? "Uploaded"
              : "AI-Generated"}
          </div>
        </div>
        <div className="exp-row-item flex items-center justify-center">
          {val?.headline === "---" ? null : (
            <Tooltip
              title="Character count"
              position="top"
              arrow="true"
              animation="scale"
            >
              <button className="btn button" disabled={true}>
                {val?.headline?.trim().length}
              </button>
            </Tooltip>
          )}
        </div>
        <div className="exp-row-item flex items-center">
          <div className="exp-row-tag">
            {val?.headline ? val?.headline : "--"}
          </div>
        </div>
        <div className="exp-row-item flex items-center">
          <div className="exp-row-tag">
            <Tooltip
              title="Set Headline Postions"
              position="top"
              arrow="true"
              animation="scale"
            >
              <DropDown
                headlinesPositionList={headlinesPositionList}
                handlePosition={handlePosition}
                headlineVal={val?.headline.trim()}
                headlinePositionVal={val?.position}
              />
            </Tooltip>
          </div>
        </div>
        {/* <div className="exp-row-item flex items-center">
          <div className="exp-row-tag">{val ? val : "--"}</div>
        </div> */}
        {/* <div className="exp-row-item flex items-center">
          {categoryData?.isLoading ? (
            <Spinner />
          ) : (
            <div className="exp-row-tag">
              {categoryType ? categoryType : ""}
            </div>
          )}
        </div> */}
        <div className="exp-row-item flex items-center justify-end">
          {/* <div
            className="eye-icon flex items-center justify-center mr-3 cursor-pointer"
            onClick={(e) => {
              submitCategoryRequestHeadline(val);
            }}
          >
            <EyeOffIcon />
          </div> */}
          <div
            className="edit-icon flex items-center justify-center cursor-pointer"
            onClick={(e) => {
              setOpenUpdateHeadline(true);
              setSelectedHeadlineVal(val?.headline.trim());
              setUpdatedHeadlineVal(val?.headline.trim());
            }}
          >
            <Tooltip
              title="Update Headline"
              position="top"
              arrow="true"
              animation="scale"
            >
              <EditIcon />
            </Tooltip>
          </div>
        </div>

        {/* <div className="exp-row-item flex items-center justify-center">
      <div className="btn button">New Variants</div>
    </div>
    <div className="exp-row-item flex items-center justify-center">
      <input
        type="text"
        className="txt-seach-key"
        placeholder="Include Keywords"
      />
    </div>
    <div className="exp-row-item flex items-center justify-center">
      <Select
        defaultValue={selectedStyle}
        value={selectedStyle}
        onChange={setSelectedStyle}
        getOptionLabel={(option) => option.display}
        getOptionValue={(option) => option.value}
        options={StyleList}
        className="select"
        id="adGroup"
        name="adGroup"
        placeholder="Select Ad-Group"
      />
    </div> */}
      </div>
    );
  };

  return (
    <>
      <div key={index} className="row-expend-block flex flex-col">
        <div className="expend-row-headline flex flex-col items-center">
          <div className="expend-row-headline-top flex">
            <div className="input-field flex flex-col w-2/5">
              <div className="input-tag text-sm font-semibold">
                Product/Service Name
              </div>
              <div className="input-tag mb-2 text-sm font-semibold text-red-700">
                Enter Name Below
              </div>
              <textarea
                name="pName"
                type="text"
                className="txt-seach-key min-h-[100px] max-h-[100px] overflow-hidden"
                placeholder="Product/Service Name"
                value={userInputProductName}
                onChange={(e) => setUserInputProductName(e.target.value)}
                onBlur={handleInput}
              />
            </div>
            <div className="input-field flex flex-col w-full">
              <div className="input-tag text-sm font-semibold">
                Product/Service Summary
              </div>
              <div
                className="input-tag flex mb-2 text-sm font-semibold text-red-700 cursor-pointer"
                onClick={(e) => {
                  setOpenGenerateUrl(true);
                  setModelData({ item, index });
                }}
              >
                Click Cloud Icon to generate a summary of your landing page{" "}
                <div className="icon cursor-pointer ml-2 h-2 w-2">
                  <CloudIcon />
                </div>
              </div>
              <textarea
                type="text"
                className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
                placeholder="Product/Service Summary"
                value={userInputProductDescription}
                onChange={(e) => setUserInputProductDescription(e.target.value)}
              />
            </div>
            <input
              type="submit"
              value={data.isLoading ? "Processing..." : "Generate Ideas"}
              className="btn button h-12 mt-8"
              onClick={(e) => submitCompletionRequestHeadline(e)}
            />
          </div>

          <div className="expend-row-headline-top flex mt-5">
            <div className="input-field flex flex-col w-full">
              <div className="input-tag text-sm font-semibold">
                Product/ Tone of Voice
              </div>
              <div
                className="input-tag flex mb-2 text-sm font-semibold text-red-700 cursor-pointer"
                onClick={(e) => setOpenGenerateUrl(true)}
              >
                Click Cloud Icon to generate a tune of voice of your landing
                page
                <div className="icon cursor-pointer ml-2 h-2 w-2">
                  <CloudIcon />
                </div>
              </div>
              <textarea
                type="text"
                className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
                placeholder="Product / Tune of voice"
                value={useInputProductToneVoice}
                onChange={(e) => setUseInputProductToneVoice(e.target.value)}
              />
            </div>
          </div>

          {/* <div className="categories-block">
            {categoriesList.map((cItem, cIndex) => (
              <div
                key={cIndex}
                className="category-box flex flex-col items-center justify-center"
              >
                <div className="category-name">{cItem.name}</div>
                <div className="category-numb">2/15</div>
                <input
                  type="submit"
                  value={data.isLoading ? "Loading..." : "Generate Ideas"}
                  className="btn-idea button"
                  onClick={(e) => {
                    submitCompletionRequestHeadlineCategory(cItem.name);
                  }}
                />
              </div>
            ))}
          </div> */}
          <div className="expend-tag">Headlines</div>
          {/* <div className="action items-center justify-center mb-10">
            <button className="btn button font-medium">
              Post Changes to Google Ads
            </button>
          </div> */}
        </div>
        <div className="expend-table-section flex flex-col">
          {data?.isLoading ? (
            <AiLoader />
          ) : (
            <>
              {countHeadlines(item)?.length ? (
                <>
                  {countHeadlines(item)?.map((val, ind) => (
                    <HeadlineRow val={val} ind={ind} />
                  ))}
                </>
              ) : null}
            </>
          )}
        </div>
        <Modal
          open={openUpdateHeadline}
          onClose={() => setOpenUpdateHeadline(false)}
        >
          <UpdateHeadlineModel
            setOpen={setOpenUpdateHeadline}
            updatedHeadlineVal={updatedHeadlineVal}
            setUpdatedHeadlineVal={setUpdatedHeadlineVal}
          />
        </Modal>
      </div>
    </>
  );
};

export default RowExpendHeadline;
