import React, { useEffect, useState } from "react";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  GoogleIcon,
  FeedIcon,
} from "../../assets/Icons";

const Footer = () => {
  return (
    <div className="footer-comp flex flex-col bg-themeColor">
      <div className="wrapWidth wrap flex items-center">
        <div className="left-side flex flex-col">
          <div className="copy-right text-lg font-medium text-white">
            Copyright of CB Digital Marketing Ltd.
          </div>
          <div className="copy-right text-lg font-medium text-white my-3">
            Trading as CapitalC Agency.
          </div>
          <div className="copy-right text-lg font-medium text-brown">
            All rights reserved
          </div>
        </div>
        <div className="center-side flex items-center justify-center">
          <a
            href="https://www.iubenda.com/privacy-policy/92945924"
            target="_blank"
            className="pp-lbl copy-right text-lg font-medium text-white"
          >
            Privacy Policy
          </a>
        </div>
        <div className="right-side flex items-center">
          {/* <div className="social-icons flex items-center">
            <div className="icon flex items-center justify-center cursor-pointer">
              <FacebookIcon />
            </div>
            <div className="icon flex items-center justify-center cursor-pointer">
              <TwitterIcon />
            </div>
            <div className="icon flex items-center justify-center cursor-pointer">
              <GoogleIcon />
            </div>
            <div className="icon flex items-center justify-center cursor-pointer">
              <InstagramIcon />
            </div>
            <a
              href="https://capitalc.agency/feed/"
              target="_blank"
              className="icon flex items-center justify-center cursor-pointer"
            >
              <FeedIcon />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
