import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const woFileJSON = JSON.parse(localStorage.getItem("woFileJSON"));
const woFileJSONDownload = JSON.parse(
  localStorage.getItem("woFileJSONDownload")
);
const woGlobalFileData = JSON.parse(localStorage.getItem("woGlobalFileData"));
const woGlobalFileForm = JSON.parse(localStorage.getItem("woGlobalFileForm"));
const expendWoRow = JSON.parse(localStorage.getItem("expendWoRow"));
const selectedWoType = JSON.parse(localStorage.getItem("selectedWoType"));
const inputWoDataDetail = JSON.parse(localStorage.getItem("inputWoDataDetail"));

const woFileReducer = createSlice({
  name: "woFile",
  initialState: {
    woGlobalFileData: woGlobalFileData
      ? woGlobalFileData
      : {
          headlines: [],
          description: [],
        },
    woGlobalFileForm: woGlobalFileForm
      ? woGlobalFileForm
      : { index: "", pName: "", pDesc: "", urlDesc: "" },
    inputWoDataDetail: inputWoDataDetail ? inputWoDataDetail : [],
    expendWoRow: expendWoRow
      ? expendWoRow
      : {
          status: true,
          index: 0,
        },
    selectedWoType: selectedWoType
      ? selectedWoType
      : {
          lbl: "headline",
          index: 0,
        },
    woFileJSON: woFileJSON
      ? woFileJSON
      : [
          {
            campaign: "",
            adGroup: "",
            adType: "",
            labels: "",
            path1: "",
            path2: "",
            finalURL: "",
            finalMobileURL: "",
            campaignStatus: "",
            adGroupStatus: "",
            status: "",
            approvalStatus: "",
            headlines: [],
            descriptions: [],
          },
        ],
    woFileJSONDownload: woFileJSONDownload ? woFileJSONDownload : [],
  },
  reducers: {
    setWoGlobalFileData: (state, action) => {
      try {
        localStorage.setItem(
          "woGlobalFileData",
          JSON.stringify(action.payload)
        );
        state.woGlobalFileData = action.payload;
      } catch (error) {
        toast.error("Local Storage Limit exceeded");
      }
    },
    setWoGlobalFileForm: (state, action) => {
      localStorage.setItem("woGlobalFileForm", JSON.stringify(action.payload));
      state.woGlobalFileForm = action.payload;
    },
    setInputWoDataDetail: (state, action) => {
      localStorage.setItem("inputWoDataDetail", JSON.stringify(action.payload));
      state.inputWoDataDetail = action.payload;
    },
    setExpendWoRow: (state, action) => {
      localStorage.setItem("expendWoRow", JSON.stringify(action.payload));
      state.expendWoRow = action.payload;
    },
    setSelectedWoType: (state, action) => {
      localStorage.setItem("selectedWoType", JSON.stringify(action.payload));
      state.selectedWoType = action.payload;
    },
    setWoFileJSON: (state, action) => {
      localStorage.setItem("woFileJSON", JSON.stringify(action.payload));
      state.woFileJSON = action.payload;
    },
    setWoFileJSONDownload: (state, action) => {
      localStorage.setItem(
        "woFileJSONDownload",
        JSON.stringify(action.payload)
      );
      state.woFileJSONDownload = action.payload;
    },
    deleteWoFileLocalData: (state, { payload }) => {
      localStorage.removeItem(payload);
      localStorage.removeItem("woFileJSON");
    },
  },
});

export const {
  setWoGlobalFileData,
  setWoGlobalFileForm,
  setInputWoDataDetail,
  setExpendWoRow,
  setSelectedWoType,
  setWoFileJSON,
  setWoFileJSONDownload,
  deleteWoFileLocalData,
} = woFileReducer.actions;
export default woFileReducer.reducer;
