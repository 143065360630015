import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { EditIcon, CloudIcon } from "../../assets/Icons";
import Modal from "../Modal";
import Loader from "../Loader";
import AiLoader from "../AiLoader";
import WofDropDown from "../DropDown/WofDropDown";
import { toast } from "react-toastify";

import {
  setWoGlobalFileData,
  setWoGlobalFileForm,
  setInputWoDataDetail,
  setWoFileJSON,
  setWoFileJSONDownload,
} from "../../store/reducers/woFileReducer";

import { useOpenAi4DescriptionArrayMutation } from "../../store/services/openAi4Service";
import UpdateWoDescription from "../WoFileRow/UpdateWoDescription";

const WoRowExpendDescription = ({
  item,
  index,
  setExpendWoRow,
  setOpenGenerateUrl,
}) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.globalReducer);
  const {
    woGlobalFileData,
    woGlobalFileForm,
    inputWoDataDetail,
    woFileJSON,
    woFileJSONDownload,
  } = useSelector((state) => state.woFileReducer);
  const { userId } = useSelector((state) => state.authReducer);

  const [completionRequest, data] = useOpenAi4DescriptionArrayMutation();

  const [openUpdateDescription, setOpenUpdateDescription] = useState(false);
  const [selectedDescriptionVal, setSelectedDescriptionVal] = useState();
  const [updatedDescriptionVal, setUpdatedDescriptionVal] = useState();

  const [userInputProductName, setUserInputProductName] = useState(
    inputWoDataDetail[index]?.name ? inputWoDataDetail[index]?.name : ""
  );
  const [userInputProductDescription, setUserInputProductDescription] =
    useState(
      inputWoDataDetail[index]?.pDesc ? inputWoDataDetail[index]?.pDesc : ""
    );

  const [useInputProductToneVoice, setUseInputProductToneVoice] = useState(
    inputWoDataDetail[index]?.toneVoiceDesc
      ? inputWoDataDetail[index]?.toneVoiceDesc
      : ""
  );

  const handleBlur = () => {
    const updatedObject = {
      index,
      pName: userInputProductName,
      pDesc: userInputProductDescription,
      toneVoiceDesc: useInputProductToneVoice,
      urlDesc: "",
    };
    const updatedArray = inputWoDataDetail.map((object) => {
      if (object.index === index) {
        return updatedObject;
      } else {
        return object;
      }
    });
    if (!updatedArray.some((object) => object.index === index)) {
      updatedArray.push(updatedObject);
    }
    dispatch(setInputWoDataDetail(updatedArray));
  };

  const submitCompletionRequestDescription = (e) => {
    if (userInputProductName === "" || userInputProductName == null) {
      toast.error("Enter Product Name!");
    } else if (
      userInputProductDescription === "" ||
      userInputProductDescription == null
    ) {
      toast.error("Enter Product Description!");
    } else {
      if (lang.value === "german") {
        completionRequest({
          userId: userId,
          model: "text-davinci-003",
          prompt: `Erstelle eine überzeugende Google Ads Beschreibung für das folgende Produkt. Stelle sicher, dass die Beschreibung maximal 90 Zeichen lang ist. Wie du weißt, können Personen auf deutsch formell und informell angesprochen werden. Wenn du den Nutzer im Text direkt ansprichst, verwende die informelle Ansprache “Du” in der zweiten Person Singular oder “Ihr” in der zweiten Person Plural. Zeichenbeschränkung: Description 1 - 90 Zeichen Description 2 - 90 Zeichen Beispiele: Description 1 - Ob zentrale Apartments, günstige Zimmer oder idyllische Ferienhäuser: Lass es Airbnb sein. Description 2 - Sichere Buchung & flexible Stornierungsmöglichkeiten. Jetzt Urlaub sorgenfrei planen! Product Name: ${userInputProductName} Product Description:  ${userInputProductDescription} Tone of Voice : ${useInputProductToneVoice} Description 1:`,
        });
      } else if (lang.value === "germanF") {
        completionRequest({
          userId: userId,
          systemContent: `Du bist professioneller Texter für Google Ads Beschreibungen.
                Du arbeitest für eine Firma, die Anzeigentexte für Google Ads Werbekunden schreibt.
      Google Ads Beschreibungen ergänzen Google Ads Überschriften. Die Überschriften gewinnen normalerweise die Aufmerksamkeit der Nutzer und regen sie dazu an, mehr über das Produkt erfahren zu wollen.
      In den Google Ads Beschreibungen finden die Nutzer dann weitere Informationen über das Produkt oder die Dienstleistung, die sie davon überzeugen, auf die Anzeige zu klicken, um sich noch weiter zu informieren und dann letztendlich das Produkt zu kaufen, die Dienstleistung zu buchen oder weitere Informationen anzufragen.
      Genau wie Überschriften haben auch Beschreibungen eine maximale Zeichenanzahl, allerdings sind sie länger als Überschriften. Du kannst maximal 90 Zeichen verwenden und hast mehr Raum, um kreativ zu sein.
      Verwende die formelle Ansprache “Sie”, wenn der Nutzer im Text direkt angesprochen wird`,
          userContent: `Erstelle überzeugend Google Ads Beschreibungen für das folgende Produkt auf Deutsch.
      Beschreibungen bestehen normalerweise aus ein oder zwei ganzen Sätzen und folgen häufig der folgenden Struktur: Unser Produkt /unsere Dienstleistung ist (ein Produkt / eine Dienstleistung), die dir (dem Kunden) bei (einem Problem) mit (einer Lösung) hilft.
      Eine weitere mögliche Struktur für Beschreibungen ist es, Nutzern zuerst das Problem vorzustellen, das sie möglicherweise erfahren, weil sie das Produkt noch nicht nutzen und dann die Lösung und wie es ihnen ergeht, wenn das Problem gelöst ist.
      Beschreibungen können auch verwendet werden, um aufzuzeigen, wie einfach es ist in Produkt zu verwenden oder eine Dienstleistung zu nutzen.
       Dein Output sollt aus 5 Beschreibungen mit minimal 65 und maximal 90 Zeichen bestehen, die alle unabhängig voneinander das Produkt oder die Dienstleistung bewerben. Numeriere die einzelnen ergebnisse nicht und verwende keine Zeilenumbrüche in den Beschreibungen.
      Die einzelnen Beschreibungen werden durch einen vertikalen Trennstrich ("|") voneinander getrennt angezeigt.
      Product Name: ${userInputProductName}
      Product Description: ${userInputProductDescription}
      Tone of Voice : ${useInputProductToneVoice}
      Ad Description`,
        });
      } else if (lang.value === "germanInF") {
        completionRequest({
          userId: userId,
          systemContent: `Du bist professioneller Texter für Google Ads Beschreibungen.
                Du arbeitest für eine Firma, die Anzeigentexte für Google Ads Werbekunden schreibt.
      Google Ads Beschreibungen ergänzen Google Ads Überschriften. Die Überschriften gewinnen normalerweise die Aufmerksamkeit der Nutzer und regen sie dazu an, mehr über das Produkt erfahren zu wollen.
      In den Google Ads Beschreibungen finden die Nutzer dann weitere Informationen über das Produkt oder die Dienstleistung, die sie davon überzeugen, auf die Anzeige zu klicken, um sich noch weiter zu informieren und dann letztendlich das Produkt zu kaufen, die Dienstleistung zu buchen oder weitere Informationen anzufragen.
      Genau wie Überschriften haben auch Beschreibungen eine maximale Zeichenanzahl, allerdings sind sie länger als Überschriften. Du kannst maximal 90 Zeichen verwenden und hast mehr Raum, um kreativ zu sein.
        Verwende die informelle Ansprache “Du”, wenn der Nutzer im Text direkt angesprochen wird`,
          userContent: `Erstelle überzeugend Google Ads Beschreibungen für das folgende Produkt auf Deutsch.
        Beschreibungen bestehen normalerweise aus ein oder zwei ganzen Sätzen und folgen häufig der folgenden Struktur: Unser Produkt /unsere Dienstleistung ist (ein Produkt / eine Dienstleistung), die dir (dem Kunden) bei (einem Problem) mit (einer Lösung) hilft.
        Eine weitere mögliche Struktur für Beschreibungen ist es, Nutzern zuerst das Problem vorzustellen, das sie möglicherweise erfahren, weil sie das Produkt noch nicht nutzen und dann die Lösung und wie es ihnen ergeht, wenn das Problem gelöst ist.
      Beschreibungen können auch verwendet werden, um aufzuzeigen, wie einfach es ist in Produkt zu verwenden oder eine Dienstleistung zu nutzen.
         Dein Output sollt aus 5 Beschreibungen mit minimal 65 und maximal 90 Zeichen bestehen, die alle unabhängig voneinander das Produkt oder die Dienstleistung bewerben. Numeriere die einzelnen ergebnisse nicht und verwende keine Zeilenumbrüche in den Beschreibungen.
        Die einzelnen Beschreibungen werden durch einen vertikalen Trennstrich ("|") voneinander getrennt angezeigt.
        Product Name: ${userInputProductName}
        Product Description: ${userInputProductDescription}
        Tone of Voice : ${useInputProductToneVoice}
        Ad Description`,
        });
      } else {
        completionRequest({
          userId: userId,
          systemContent: `You are an expert content writer for Google Search Ad Descriptions.
                              You work for a company that writes search copy for clients.
                              Google Ads Descriptions complement Google Ads Headlines, which usually get the attention of the user and entices them to learn more about the product or service.
                              Google Ads descriptions then provide the user with more information about the product or service in a manner whcih ultimately convinces them to click on the ad.
                              Like headlines, descriptions have a character limit though they are longer than headlines (90 characters), so you have more room to be creative.`,
          userContent: `Create compelling Google Ads Descriptions for the following product in English.
                            Descriptions usually consists of one or two full sentences having variants of the following structure: Our product/service is a (product/service) that (benefit) you the customer facing (problem) by (solution).
                            An additonal template for descriptions is to take users through a journey of the product or service, starting with the problem, then the solution, and finally the benefit.
                            Descriptions can also be used to drive actions showcasing things like how easy it is to get started with the product or service, or how easy it is to use the product or service.
                            Your output should consist of 5 descriptions of up to 90 characters each that promote your product or service.The descriptions are separated from each other by a vertical bar ("|").
                            Do not number your headline or have any punctuation at the end of your headline.
                            Do not have any carriage returns in your output.
                            Product Name: ${userInputProductName}
                            Product Description: ${userInputProductDescription}
                            Tone of Voice : ${useInputProductToneVoice}
                            Ad Description:`,
        });
      }
    }
  };

  useEffect(() => {
    const myObject = inputWoDataDetail.find((item) => item.index === index);
    setUserInputProductName(myObject?.pName);
    setUserInputProductDescription(myObject?.pDesc);
    setUseInputProductToneVoice(myObject?.toneVoiceDesc);
  }, [inputWoDataDetail]);

  const [arrayObj, setArrayObj] = useState(woFileJSON ? woFileJSON[index] : []);

  useEffect(() => {
    if (data.isSuccess) {
      const myObject = { ...arrayObj };

      //Update the state with the new object
      const updatedObject = {
        ...myObject,
        descriptions: [
          ...myObject.descriptions,
          ...data.data?.message?.map((description) => ({
            description,
            position: "",
            category: "",
          })),
        ],
      };

      setArrayObj(updatedObject);
    }
  }, [data.isSuccess]);

  useEffect(() => {
    var nArr = [...woFileJSON];
    nArr[index] = arrayObj;
    const myObject = inputWoDataDetail.find((item) => item.index === index);
    if (data.isSuccess) {
      setTimeout(() => {
        dispatch(setWoFileJSON(nArr));
        const updatedObject = {
          index,
          pName: userInputProductName,
          pDesc: userInputProductDescription,
          urlDesc: myObject ? myObject?.urlDesc : "",
          toneVoiceDesc: myObject ? myObject?.toneVoiceDesc : "",
        };
        const updatedArray = inputWoDataDetail.map((object) => {
          if (object.index === index) {
            return updatedObject;
          } else {
            return object;
          }
        });
        if (!updatedArray.some((object) => object.index === index)) {
          updatedArray.push(updatedObject);
        }
        dispatch(setInputWoDataDetail(updatedArray));
      }, 2000);
    }
  }, [arrayObj]);

  useEffect(() => {
    if (updatedDescriptionVal !== selectedDescriptionVal?.desc) {
      const objectToUpdate = woFileJSON[index];

      // Update the second description in the descriptions array of the object at index
      const updatedDescriptions = objectToUpdate.descriptions.map(
        (descriptionObject, indexIn) => {
          if (indexIn === selectedDescriptionVal?.ValInd) {
            // Update the headline object at the specified index
            return { ...descriptionObject, description: updatedDescriptionVal };
          } else {
            return descriptionObject;
          }
        }
      );

      // Create a new object with the updated descriptions array
      const updatedObject = {
        ...objectToUpdate,
        descriptions: updatedDescriptions,
      };

      // Create a new array with the updated object at indexToUpdate
      const updatedData = [
        ...woFileJSON.slice(0, index),
        updatedObject,
        ...woFileJSON.slice(index + 1),
      ];

      dispatch(setWoFileJSON(updatedData));
    }
  }, [updatedDescriptionVal]);

  const DescriptionRow = ({ val, ind }) => {
    const descriptionPositionList = [
      { label: "1", position: "1" },
      { label: "2", position: "2" },
      { label: "No Position", position: "--" },
    ];

    const handlePosition = (e, checked, headline_obj) => {
      if (checked) {
        let rowindex = woFileJSONDownload?.findIndex(
          (item) => item?.index === index
        );
        let woFileJSONDownloadTemp = [...woFileJSONDownload];
        if (rowindex > -1) {
          let headlineindex = woFileJSONDownload[
            rowindex
          ]?.descriptions?.findIndex(
            (item) => item?.description === headline_obj?.description
          );
          let the_headlines = [...woFileJSONDownload[rowindex]?.descriptions];
          the_headlines[headlineindex] = {
            ...headline_obj,
            position: e.position,
          };
          woFileJSONDownloadTemp[rowindex] = {
            ...woFileJSONDownloadTemp[rowindex],
            descriptions: the_headlines,
          };
        }
        dispatch(setWoFileJSONDownload(woFileJSONDownloadTemp));
      }

      const objectToUpdate = woFileJSON[index];

      // Update the second description in the descriptions array of the object at index
      const updatedDescriptions = objectToUpdate.descriptions.map(
        (descriptionObject, indexIn) => {
          if (indexIn === ind) {
            // Update the headline object at the specified index
            return { ...descriptionObject, position: e?.position };
          } else {
            return descriptionObject;
          }
        }
      );

      // Create a new object with the updated descriptions array
      const updatedObject = {
        ...objectToUpdate,
        descriptions: updatedDescriptions,
      };

      // Create a new array with the updated object at indexToUpdate
      const updatedData = [
        ...woFileJSON.slice(0, index),
        updatedObject,
        ...woFileJSON.slice(index + 1),
      ];

      dispatch(setWoFileJSON(updatedData));
    };

    const handleClick = async (headline_obj) => {
      let woFileJSONDownloadTemp = [...woFileJSONDownload];
      let rowindex = woFileJSONDownload?.findIndex(
        (item) => item?.index === index
      );

      if (rowindex > -1) {
        let headlineindex = woFileJSONDownload[
          rowindex
        ]?.descriptions?.findIndex(
          (item) => item?.description === headline_obj?.description
        );
        let the_headlines = [...woFileJSONDownload[rowindex]?.descriptions];
        if (headlineindex > -1) {
          the_headlines.splice(headlineindex, 1);
        } else {
          if (the_headlines.length >= 4) {
            return toast.error(
              "You are trying to select more than 4 description. Please uncheck existing descriptions before selecting new description to add to your ad group"
            );
          } else {
            the_headlines = [...the_headlines, headline_obj];
          }
        }

        woFileJSONDownloadTemp[rowindex] = {
          ...woFileJSONDownloadTemp[rowindex],
          descriptions: the_headlines,
        };
      } else {
        woFileJSONDownloadTemp.push({
          index,
          ...woFileJSON[index],
          descriptions: [headline_obj],
        });
      }
      dispatch(setWoFileJSONDownload(woFileJSONDownloadTemp));
    };
    let WoFileJsonDownload = woFileJSONDownload[index];
    let checkedstatus =
      WoFileJsonDownload?.descriptions?.findIndex(
        (item) => item?.description == val.description
      ) > -1
        ? true
        : false;

    return (
      <div key={ind} className="exp-row flex items-center">
        <div className="exp-row-item flex items-center">
          <div className="mr-2">{ind + 1}</div>
          <input
            onClick={(e) => {
              handleClick(val);
            }}
            checked={checkedstatus}
            type="checkbox"
            className="check-box w-5 h-5"
          />
        </div>
        <div className="exp-row-item flex items-center justify-center">
          <Tooltip
            title="Character count"
            position="top"
            arrow="true"
            animation="scale"
          >
            <button className="btn button" disabled={true}>
              {val?.description.trim().length}
            </button>
          </Tooltip>
        </div>
        <div className="exp-row-item flex items-center">
          <div className="exp-row-tag">
            {val?.description ? val?.description : "--"}
          </div>
        </div>
        <div className="exp-row-item flex items-center">
          <div className="exp-row-tag">
            <Tooltip
              title="Set Description Postions"
              position="top"
              arrow="true"
              animation="scale"
            >
              <WofDropDown
                headlinesPositionList={descriptionPositionList}
                handlePosition={(e) => handlePosition(e, checkedstatus, val)}
                headlinePositionVal={val}
              />
            </Tooltip>
          </div>
        </div>
        <div className="exp-row-item flex items-center ml-3">
          <div
            className="edit-icon flex items-center justify-center cursor-pointer"
            onClick={(e) => {
              setOpenUpdateDescription(true);
              setSelectedDescriptionVal({
                desc: val?.description.trim(),
                ValInd: ind,
              });
              setUpdatedDescriptionVal(val?.description?.trim());
            }}
          >
            <Tooltip
              title="Update Description"
              position="top"
              arrow="true"
              animation="scale"
            >
              <EditIcon />
            </Tooltip>
          </div>
        </div>

        {/* <div className="exp-row-item flex items-center justify-center">
        <div className="btn button">New Variants</div>
      </div>
      <div className="exp-row-item flex items-center justify-center">
        <input
          type="text"
          className="txt-seach-key"
          placeholder="Include Keywords"
        />
      </div>
      <div className="exp-row-item flex items-center justify-center">
        <Select
          defaultValue={selectedStyle}
          value={selectedStyle}
          onChange={setSelectedStyle}
          getOptionLabel={(option) => option.display}
          getOptionValue={(option) => option.value}
          options={StyleList}
          className="select"
          id="adGroup"
          name="adGroup"
          placeholder="Select Ad-Group"
        />
      </div> */}
      </div>
    );
  };

  return (
    <div key={index} className="row-expend-block flex flex-col">
      <div className="expend-row-headline flex items-center flex-col">
        <div className="expend-row-headline-top flex">
          <div className="input-field flex flex-col w-2/5">
            <div className="input-tag text-sm font-semibold">
              Product/Service Name
            </div>
            <div className="input-tag mb-2 text-sm font-semibold text-red-700">
              Enter Name Below
            </div>
            <textarea
              id="pName"
              type="text"
              className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
              placeholder="Product/Service Name"
              value={userInputProductName}
              onChange={(e) => setUserInputProductName(e.target.value)}
              onBlur={handleBlur}
            />
          </div>
          <div className="input-field flex flex-col w-full">
            <div className="input-tag text-sm font-semibold">
              Product/Service Summary
            </div>
            <div
              className="input-tag flex mb-2 text-sm font-semibold text-red-700 cursor-pointer"
              onClick={(e) => setOpenGenerateUrl(true)}
            >
              Click Cloud Icon to generate a summary of your landing page{" "}
              <div className="icon cursor-pointer ml-2 h-2 w-2">
                <CloudIcon />
              </div>
            </div>
            <textarea
              type="text"
              className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
              placeholder="Product/Service Summary"
              value={userInputProductDescription}
              onChange={(e) => setUserInputProductDescription(e.target.value)}
            />
          </div>
          <input
            type="submit"
            value={data.isLoading ? "Processing..." : "Generate Ideas"}
            className="btn button h-12 mt-8"
            onClick={(e) => submitCompletionRequestDescription(e)}
          />
        </div>
        <div className="expend-row-headline-top flex mt-5">
          <div className="input-field flex flex-col w-full">
            <div className="input-tag text-sm font-semibold">
              Product/ Tone of Voice
            </div>
            <div
              className="input-tag flex mb-2 text-sm font-semibold text-red-700 cursor-pointer"
              onClick={(e) => setOpenGenerateUrl(true)}
            >
              Click Cloud Icon to generate a tune of voice of your landing page
              <div className="icon cursor-pointer ml-2 h-2 w-2">
                <CloudIcon />
              </div>
            </div>
            <textarea
              type="text"
              className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
              placeholder="Product / Tune of voice"
              value={useInputProductToneVoice}
              onChange={(e) => setUseInputProductToneVoice(e.target.value)}
            />
          </div>
        </div>

        <div className="expend-tag">Description</div>
      </div>
      <div className="expend-table-section flex flex-col">
        {data?.isLoading ? (
          <AiLoader />
        ) : (
          <>
            {item?.length ? (
              <>
                {item?.map((val, ind) => (
                  <DescriptionRow val={val} ind={ind} />
                ))}
              </>
            ) : (
              <div className="text-lg font-semibold text-center mt-20 mb-10 text-rose-700">
                No descriptions were generated. Please press “Generate Ideas”
                for more output.
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        open={openUpdateDescription}
        onClose={() => setOpenUpdateDescription(false)}
      >
        <UpdateWoDescription
          setOpen={setOpenUpdateDescription}
          updatedDescriptionVal={updatedDescriptionVal}
          setUpdatedDescriptionVal={setUpdatedDescriptionVal}
        />
      </Modal>
    </div>
  );
};

export default WoRowExpendDescription;
