import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
const customerToken = localStorage.getItem("userToken");
const userId = localStorage.getItem("userId");

function verifyToken(keyName) {
  const storage = localStorage.getItem(keyName);
  if (storage) {
    const decodeToken = jwtDecode(storage);
    const expiresIn = new Date(decodeToken.exp * 1000);
    if (new Date() > expiresIn) {
      localStorage.removeItem(keyName);
      return null;
    } else {
      return storage;
    }
  } else {
    return null;
  }
}
const authReducer = createSlice({
  name: "authReducer",
  initialState: {
    userToken: verifyToken("userToken"),
    userId: userId ? userId : "",
    user: customerToken ? jwtDecode(customerToken) : null,
  },
  reducers: {
    setUserToken: (state, action) => {
      state.userToken = action.payload;
      state.user = jwtDecode(action.payload);
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    logout: (state, { payload }) => {
      localStorage.removeItem(payload);
      localStorage.removeItem("userToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("localData");
      localStorage.removeItem("globalData");
      localStorage.removeItem("expendRow");
      localStorage.removeItem("selectedType");
      localStorage.removeItem("formInfo");
      localStorage.removeItem("inputDataDetail");
      localStorage.removeItem("csvReadedData");
      state.userId = null;
      state.adminToken = null;
      state.userToken = null;
      state.user = null;
    },
  },
});
export const { setUserToken, setUserId, logout } = authReducer.actions;
export default authReducer.reducer;
