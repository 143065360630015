import { useState, useEffect } from "react";
import { useUserRegisterMutation } from "../../store/services/authService";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { EyeOnIcon, EyeOffIcon } from "../../assets/Icons";

import { Country, State, City } from "country-state-city";
// console.log(Country.getAllCountries());

const Registration = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const companyTypeList = [
    { display: "Agency", value: "agency" },
    { display: "Brand", value: "brand" },
    { display: "Freelance", value: "freelance" },
  ];
  const initialValues = {
    name: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    companyURL: "",
    companyType: "",
    terms: false,
    address: {
      country: "",
      email: "",
    },
  };
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]\\|:;"'<>,.?/~`])(?=.*[^\da-zA-Z]).{8,}$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        strongPasswordRegex,
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    companyName: Yup.string().required("Company name is required"),
    companyURL: Yup.string().required("Company URL is required"),
    companyType: Yup.string().required("Company type is required"),
    terms: Yup.boolean().oneOf(
      [true],
      "Please review and accept the Terms and Conditions for RSAPro SAAS Tool provided by CB Digital Marketing Limited before proceeding with registration"
    ),
    address: Yup.object().shape({
      country: Yup.string().required("Country is required"),
      email: Yup.string()
        .required("Email is required")
        .matches(emailRegex, "Invalid email address"),
    }),
  });

  const [register, response] = useUserRegisterMutation();

  const errors = response?.error?.data?.errors
    ? response?.error?.data?.errors
    : [];

  const adminLoginFunction = (e) => {
    register(e);
  };

  useEffect(() => {
    if (response.isSuccess) {
      toast.success(`${response?.data?.msg}`);
      navigate("/auth/login");
    }
  }, [response.isSuccess]);

  useEffect(() => {
    if (response.isError) {
      if (response?.error?.data?.errors?.length) {
        toast.error(`${response?.error?.data?.errors[0]?.msg}`);
      } else {
        toast.error(`Server internal error!`);
      }
    }
  }, [response.isError]);

  return (
    <div className="register-page bg-themeColor min-h-screen flex justify-center items-center px-3 py12">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={adminLoginFunction}
      >
        {(formik) => (
          <Form className="inner-box flex flex-col bg-white p-5 w-[500px] sm:[300px] md:[500px] lg:[500px] rounded my-5">
            <h3 className="mb-4 text-black capitalize font font-bold text-lg">
              Register for Beta Access
            </h3>
            <div className="mb-4 mt-4 flex flex-col">
              <Field
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                className="w-full font-medium text-lg bg-[#eee] p-2 rounded outline-none text-black1"
              />
              <div className="text-red-700 ">
                <ErrorMessage name="name" />
              </div>
            </div>

            <div className="mb-4 flex flex-col">
              <Field
                type="email"
                id="address.email"
                name="address.email"
                placeholder="example@gmail.com"
                className="w-full font-medium text-lg bg-[#eee] p-2 rounded outline-none text-black1"
              />
              <div className="text-red-700">
                <ErrorMessage name="email" />
              </div>
            </div>

            <div className="mb-4 flex flex-col">
              <div className="flex items-center bg-[#eee] p-2 rounded outline-none">
                <Field
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="password"
                  className="w-full font-medium text-lg text-black1 bg-transparent"
                />
                <button
                  className="show-icon cleanbtn flex items-center justify-center cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <EyeOffIcon /> : <EyeOnIcon />}
                </button>
              </div>
              <div className="text-red-700">
                <ErrorMessage name="password" />
              </div>
            </div>

            <div className="mb-4 flex flex-col">
              <div className="flex items-center bg-[#eee] p-2 rounded outline-none">
                <Field
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="confirmPassword"
                  className="w-full font-medium text-lg text-black1 bg-transparent"
                />
                <button
                  className="show-icon cleanbtn flex items-center justify-center cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                >
                  {showConfirmPassword ? <EyeOffIcon /> : <EyeOnIcon />}
                </button>
              </div>
              <div className="text-red-700">
                <ErrorMessage name="confirmPassword" />
              </div>
            </div>

            <div className="mb-4 flex flex-col">
              <Field
                type="text"
                id="companyName"
                name="companyName"
                placeholder="Company Name"
                className="w-full font-medium text-lg bg-[#eee] p-2 rounded outline-none text-black1"
              />
              <div className="text-red-700">
                <ErrorMessage name="companyName" />
              </div>
            </div>

            <div className="mb-4 flex flex-col">
              <Field
                type="text"
                id="companyURL"
                name="companyURL"
                placeholder="Company URL"
                className="w-full font-medium text-lg bg-[#eee] p-2 rounded outline-none text-black1"
              />
              <div className="text-red-700">
                <ErrorMessage name="companyURL" />
              </div>
            </div>

            <div className="mb-4 flex flex-col">
              <Select
                onChange={(selectedOption) => {
                  formik?.setFieldValue("companyType", selectedOption.display);
                }}
                getOptionLabel={(option) => option.display}
                getOptionValue={(option) => option.value}
                options={companyTypeList}
                className="select w-full"
                id="companyType"
                name="companyType"
                placeholder="Company Type"
              />
              <div className="text-red-700">
                <ErrorMessage name="companyType" />
              </div>
            </div>
            <div className="mb-4 flex flex-col">
              <Select
                onChange={(selectedOption) => {
                  formik?.setFieldValue("address.country", selectedOption.name);
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.isoCode}
                options={Country.getAllCountries()}
                className="select w-full"
                id="country"
                name="address.country"
                placeholder="Country Name"
              />
              <div className="text-red-700">
                <ErrorMessage name="address.country" />
              </div>
            </div>
            <div className="mb-4 flex flex-col">
              <Field
                name="terms"
                type="checkbox"
                className="flex items-center cursor-pointer"
              >
                {({ field }) => (
                  <div className="flex cursor-pointer">
                    <div className="left">
                      <input
                        {...field}
                        id="terms"
                        type="checkbox"
                        className="h-8 w-5 mr-2"
                        checked={field.value}
                      />
                    </div>
                    <div className="right">
                      <label htmlFor="terms">
                        I have read and agree to the{" "}
                        <Link
                          to="/auth/terms"
                          className="underline text-red-600"
                        >
                          Terms and Conditions
                        </Link>{" "}
                        for the RSAPro SAAS tool provided by CB Digital
                        Marketing Limited. By clicking this checkbox, I
                        acknowledge that I have read, understand, and agree to
                        be bound by the Terms and Conditions.
                      </label>
                    </div>
                  </div>
                )}
              </Field>
              <div className="text-red-700 mt-5">
                <ErrorMessage name="terms" />
              </div>
            </div>

            <input
              type="submit"
              value={response.isLoading ? "Loading..." : "Register"}
              className="bg-themeColor w-full p-4 rounded text-white uppercase font-semibold cursor-pointer"
            />

            <div className="mb-4 flex items-center justify-end w-full">
              <Link
                to="/auth/login"
                className="text-black capitalize mt-4 font font-medium text-lg cursor-pointer hover:underline"
              >
                Registered User? Login
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Registration;
