import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip } from "react-tippy";

import { EditIcon, CloudIcon, EyeOffIcon, EyeOnIcon } from "../../assets/Icons";
import Modal from "../Modal";
import Loader from "../Loader";
import AiLoader from "../AiLoader";
import WofDropDown from "../DropDown/WofDropDown";

import {
  setWoGlobalFileData,
  setWoGlobalFileForm,
  setInputWoDataDetail,
  setWoFileJSON,
  setWoFileJSONDownload,
} from "../../store/reducers/woFileReducer";

import { useOpenAiGenerateCategoryMutation } from "../../store/services/openAiService";
import { useOpenAi4HeadlineArrayMutation } from "../../store/services/openAi4Service";
import UpdateWoHeadline from "../WoFileRow/UpdateWoHeadline";
import Spinner from "../Spinner";

const WoRowExpendHeadline = ({
  item,
  index,
  setExpendWoRow,
  setselectedHeadlines,
  selectedHeadlines,
  setOpenGenerateUrl,
}) => {
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.globalReducer);

  const {
    woGlobalFileData,
    woGlobalFileForm,
    woFileJSON,
    woFileJSONDownload,
    inputWoDataDetail,
  } = useSelector((state) => state.woFileReducer);

  const { userId } = useSelector((state) => state.authReducer);

  const [completionRequest, data] = useOpenAi4HeadlineArrayMutation();

  const [openUpdateHeadline, setOpenUpdateHeadline] = useState(false);
  const [selectedHeadlineVal, setSelectedHeadlineVal] = useState();
  const [selectedVal, setSelectedVal] = useState({});
  const [updatedHeadlineVal, setUpdatedHeadlineVal] = useState();
  const categoriesList = [
    { name: "Call to Action" },
    { name: "Bran Messaging" },
    { name: "Competitive" },
    { name: "Feature" },
    { name: "Problem Statement" },
    { name: "Pricing & Deals" },
    { name: "Social Proof" },
    { name: "Benefits" },
  ];

  const [userInputProductName, setUserInputProductName] = useState();

  const [userInputProductDescription, setUserInputProductDescription] =
    useState();

  const [useInputProductToneVoice, setUseInputProductToneVoice] = useState();

  const handleBlur = () => {
    const updatedObject = {
      index,
      pName: userInputProductName,
      pDesc: userInputProductDescription,
      urlDesc: userInputProductDescription,
      toneVoiceDesc: useInputProductToneVoice,
    };
    const updatedArray = inputWoDataDetail.map((object) => {
      if (object.index === index) {
        return updatedObject;
      } else {
        return object;
      }
    });

    if (!updatedArray.some((object) => object.index === index)) {
      updatedArray.push(updatedObject);
    }

    dispatch(setInputWoDataDetail(updatedArray));
  };

  const submitCompletionRequestHeadline = (e) => {
    // e.preventDefault();
    if (userInputProductName === "" || userInputProductName == null) {
      toast.error("Enter Product Name!");
    } else if (
      userInputProductDescription === "" ||
      userInputProductDescription == null
    ) {
      toast.error("Enter Product Description!");
    } else {
      if (lang.value === "german") {
        completionRequest({
          userId: userId,
          systemContent: "text-davinci-003",
          userContent: `
          Erstelle eine überzeugende Google Ads Überschrift für das folgende Produkt.
          **Nutzer nehmen vor allem die Texte in den Überschriften zur Kenntnis, sie sollten darum wenn möglich die Worte enthalten, die die Nutzer auch in die Google Suche eingegeben haben. Deine Anzeige besteht aus 3 Überschriften mit jeweils bis zu 30 Zeichen, in denen dein Produkt oder deine Dienstleistung beworben wird. Die Überschriften werden durch einen vertikalen Strich ("|") voneinander getrennt dargestellt.**
          Product Name: ${userInputProductName}
          Product Description: ${userInputProductDescription}
           Tone of Voice : ${useInputProductToneVoice}
          Ad Headline:
          `,
        });
      } else if (lang.value === "germanF") {
        completionRequest({
          userId: userId,
          systemContent: `Du bist professioneller Texter für Google Ads Überschriften.
          Du arbeitest für eine Firma, die Anzeigentexte für Google Ads Werbekunden schreibt.
          Überschriften in Suchanzeigen müssen eine Menge Informationen übermitteln und ein positives Gefühl über ein Produkt oder eine Dienstleistung in weniger als 30 Zeichen übermitteln.
          Überschriften sind kurz, ausdrucksstark, bringen den Inhalt auf den Punkt und erregen die Aufmerksamkeit potenzieller Kunden.
          Überschrifen sollen eine anziehende Wirkung auf die Zielgruppe ausüben, damit die Nutzer den Rest der Anzeige durchlesen und davon überzeugt werden, auf die Website durchzuklicken.
          Verwende die formelle Ansprache “Sie”, wenn der Nutzer im Text direkt angesprochen wird`,
          userContent: `Erstelle überzeugende Google Ads Überschriften für das folgende Produkt auf Deutsch.
          Der Output sollten 10 Überschriften mit maximal 30 Zeichen sein, die alle das Produkt oder die Dienstleistung bewerben.
          Die erstellten Headlines sollten divers sein, dafür sollten sie unterschiedliche Kategorien enthalten: Handlungsaufforderungen, Produkteigenschaften, Social Proof, Produktvorteile, Problemaussagen von Kunden und Sonderangebote oder Preisinformationen.
          Numeriere die Überschriften nicht und nutze keine Satzzeichen am Ende der Überschrift.
          Achten Sie darauf, dass der Stil der Schlagzeilen mit dem Tonfall der unten geteilten Zielseite übereinstimmt
         Die einzelnen Überschriften werden durch einen vertikalen Trennstrich ("|") voneinander getrennt angezeigt.
         Product Name: ${userInputProductName}
         Product Description: ${userInputProductDescription}
          Tone of Voice : ${useInputProductToneVoice}
         Ad Headlines:`,
        });
      } else if (lang.value === "germanInF") {
        completionRequest({
          userId: userId,
          systemContent: `Du bist professioneller Texter für Google Ads Überschriften.
          Du arbeitest für eine Firma, die Anzeigentexte für Google Ads Werbekunden schreibt.
          Überschriften in Suchanzeigen müssen eine Menge Informationen übermitteln und ein positives Gefühl über ein Produkt oder eine Dienstleistung in weniger als 30 Zeichen übermitteln.
          Überschriften sind kurz, ausdrucksstark, bringen den Inhalt auf den Punkt und erregen die Aufmerksamkeit potenzieller Kunden.
          Überschrifen sollen eine anziehende Wirkung auf die Zielgruppe ausüben, damit die Nutzer den Rest der Anzeige durchlesen und davon überzeugt werden, auf die Website durchzuklicken.
          Verwende die informelle Ansprache “Du”, wenn der Nutzer im Text direkt angesprochen wird`,
          userContent: `Erstelle überzeugende Google Ads Überschriften für das folgende Produkt auf Deutsch.
          Der Output sollten 10 Überschriften mit maximal 30 Zeichen sein, die alle das Produkt oder die Dienstleistung bewerben.
          Die erstellten Headlines sollten divers sein, dafür sollten sie unterschiedliche Kategorien enthalten: Handlungsaufforderungen, Produkteigenschaften, Social Proof, Produktvorteile, Problemaussagen von Kunden und Sonderangebote oder Preisinformationen.
          Numeriere die Überschriften nicht und nutze keine Satzzeichen am Ende der Überschrift.
         Die einzelnen Überschriften werden durch einen vertikalen Trennstrich ("|") voneinander getrennt angezeigt.
         Product Name: ${userInputProductName}
         Product Description: ${userInputProductDescription}
          Tone of Voice : ${useInputProductToneVoice}
         Ad Headlines:`,
        });
      } else {
        completionRequest({
          userId: userId,
          systemContent: `You are an expert content writer for Google Search Ad Headlines. 
          You work for a company that writes search copy for clients.
          Search Ad Headlines have to convey a lot of information and also trigger positive emotions about the product or service within 30 characters.
          Headlines are designed to be short, punchy, and to catch the attention of your potential customers. 
          They should entice your audience and convince them to read the rest of your ad and ultimately click on it.
          `,
          userContent: `Create compelling Google Ads Headlines for the following product in English.
          Your output should consist of 10 headlines of up to 30 characters each that promote your product or service.
          Have a diversity of headline types in your output, including:Calls to Action, Highlighting Customer Problems, Features, Benefits, and Emotions.
          Weave in key facts and differentiated features shared from the product description shared below
          Keep the style of the headlines consistent with the tone of voice of the landing page shared below
          Do not number your headline or have any punctuation at the end of your headline.
          Do not encapsulate your headlines with double or single quotes
          The headings are separated from each other by a vertical bar ("|"). 
         Product Name: ${userInputProductName} 
         Product Description: ${userInputProductDescription} 
         Tone of Voice : ${useInputProductToneVoice}
         Ad Headlines:`,
        });
      }
    }
  };

  useEffect(() => {
    const myObject = inputWoDataDetail.find((item) => item.index === index);
    setUserInputProductName(myObject?.pName);
    setUserInputProductDescription(myObject?.pDesc);
    setUseInputProductToneVoice(myObject?.toneVoiceDesc);
  }, [inputWoDataDetail]);

  const [arrayObj, setArrayObj] = useState(woFileJSON ? woFileJSON[index] : []);

  useEffect(() => {
    if (data.isSuccess) {
      const myObject = { ...arrayObj };

      // Create a new array of headlines
      const updatedObject = {
        ...myObject,
        headlines: [
          ...myObject.headlines,
          ...data.data?.message?.map((headline) => ({
            headline,
            position: "",
            category: "",
          })),
        ],
      };

      //Update the state with the new object
      setArrayObj(updatedObject);
    }
  }, [data.isSuccess]);

  useEffect(() => {
    var nArr = [...woFileJSON];
    nArr[index] = arrayObj;

    const myObject = inputWoDataDetail.find((item) => item.index === index);
    if (data.isSuccess) {
      setTimeout(() => {
        dispatch(setWoFileJSON(nArr));
        const updatedObject = {
          index,
          pName: userInputProductName,
          pDesc: userInputProductDescription,
          urlDesc: myObject ? myObject?.urlDesc : "",
          toneVoiceDesc: useInputProductToneVoice,
        };
        const updatedArray = inputWoDataDetail.map((object) => {
          if (object.index === index) {
            return updatedObject;
          } else {
            return object;
          }
        });
        if (!updatedArray.some((object) => object.index === index)) {
          updatedArray.push(updatedObject);
        }
        dispatch(setInputWoDataDetail(updatedArray));
        setExpendWoRow(true);
      }, 2000);
    }
  }, [arrayObj]);

  const editTitleinWoFileJSONDownload = (headline_obj) => {
    let rowindex = woFileJSONDownload?.findIndex(
      (item) => item?.index === index
    );
    let woFileJSONDownloadTemp = [...woFileJSONDownload];
    if (rowindex > -1) {
      let headlineindex = woFileJSONDownload[rowindex]?.headlines?.findIndex(
        (item) => item?.headline === headline_obj?.headline
      );
      let the_headlines = [...woFileJSONDownload[rowindex]?.headlines];
      the_headlines[headlineindex] = {
        ...headline_obj,
        headline: updatedHeadlineVal,
      };
      woFileJSONDownloadTemp[rowindex] = {
        ...woFileJSONDownloadTemp[rowindex],
        headlines: the_headlines,
      };
    }
    dispatch(setWoFileJSONDownload(woFileJSONDownloadTemp));
  };

  useEffect(() => {
    if (selectedVal?.checkedstatus) {
      editTitleinWoFileJSONDownload(selectedVal?.val);
    }
    // Update the specific row headline
    if (updatedHeadlineVal !== selectedHeadlineVal?.desc) {
      const objectToUpdate = woFileJSON[index];

      // Update the second headline in the headlines array of the object at index
      const updatedHeadlines = objectToUpdate.headlines.map(
        (headlineObject, indexIn) => {
          if (indexIn === selectedHeadlineVal?.ValInd) {
            // Update the headline object at the specified index
            return { ...headlineObject, headline: updatedHeadlineVal };
          } else {
            return headlineObject;
          }
        }
      );

      // Create a new object with the updated headlines array
      const updatedObject = { ...objectToUpdate, headlines: updatedHeadlines };

      // Create a new array with the updated object at indexToUpdate
      const updatedData = [
        ...woFileJSON.slice(0, index),
        updatedObject,
        ...woFileJSON.slice(index + 1),
      ];

      dispatch(setWoFileJSON(updatedData));
    }
  }, [updatedHeadlineVal]);

  const updateSelectedHeadlines = (Headline) => {
    // check if value exist by index, then remove it either add it

    if (selectedHeadlines?.[`row${index}`]) {
      let foundedIndex = selectedHeadlines?.[`row${index}`]?.findIndex(
        (item) => item?.Headline == Headline
      );
      if (foundedIndex > -1) {
        let an_array = selectedHeadlines?.[`row${index}`];

        an_array?.splice(foundedIndex, 1);

        selectedHeadlines[`row${index}`] = an_array;
      } else {
        if (selectedHeadlines?.[`row${index}`]?.length >= 15) {
          return toast.error(
            "You are trying to select more than 15 headlines. Please uncheck existing headlines before selecting new headline to add to your adg roup"
          );
        } else {
          selectedHeadlines[`row${index}`]?.push({ Headline });
        }
      }
    } else {
      selectedHeadlines[`row${index}`] = [{ Headline }];
    }
    setselectedHeadlines({ ...selectedHeadlines });
  };

  const HeadlineRow = ({ val, ind }) => {
    const [categoryRequest, categoryData] = useOpenAiGenerateCategoryMutation();
    const [categoryType, setCategoryType] = useState("");
    const submitCategoryRequestHeadline = async (val) => {
      await categoryRequest({
        prompt: `Headline: ${val}\nCategory:`,
      });
    };

    const headlinesPositionList = [
      { label: "1", position: "1" },
      { label: "2", position: "2" },
      { label: "3", position: "3" },
      { label: "No Position", position: "--" },
    ];

    const handlePosition = (e, checked, headline_obj) => {
      if (checked) {
        let rowindex = woFileJSONDownload?.findIndex(
          (item) => item?.index === index
        );
        let woFileJSONDownloadTemp = [...woFileJSONDownload];
        if (rowindex > -1) {
          let headlineindex = woFileJSONDownload[
            rowindex
          ]?.headlines?.findIndex(
            (item) => item?.headline === headline_obj?.headline
          );
          let the_headlines = [...woFileJSONDownload[rowindex]?.headlines];
          the_headlines[headlineindex] = {
            ...headline_obj,
            position: e.position,
          };
          woFileJSONDownloadTemp[rowindex] = {
            ...woFileJSONDownloadTemp[rowindex],
            headlines: the_headlines,
          };
        }
        dispatch(setWoFileJSONDownload(woFileJSONDownloadTemp));
      }
      const objectToUpdate = woFileJSON[index];

      // Update the second headline in the headlines array of the object at index
      const updatedHeadlines = objectToUpdate.headlines.map(
        (headlineObject, indexIn) => {
          if (indexIn === ind) {
            // Update the headline object at the specified index
            return { ...headlineObject, position: e?.position };
          } else {
            return headlineObject;
          }
        }
      );

      // Create a new object with the updated headlines array
      const updatedObject = { ...objectToUpdate, headlines: updatedHeadlines };

      // Create a new array with the updated object at indexToUpdate
      const updatedData = [
        ...woFileJSON.slice(0, index),
        updatedObject,
        ...woFileJSON.slice(index + 1),
      ];

      dispatch(setWoFileJSON(updatedData));
    };

    useEffect(() => {
      setCategoryType(categoryData?.data?.category);
    }, [categoryData?.isSuccess]);

    const handleClick = async (headline_obj) => {
      let woFileJSONDownloadTemp = [...woFileJSONDownload];
      let rowindex = woFileJSONDownload?.findIndex(
        (item) => item?.index === index
      );

      if (rowindex > -1) {
        let headlineindex = woFileJSONDownload[rowindex]?.headlines?.findIndex(
          (item) => item?.headline === headline_obj?.headline
        );
        let the_headlines = [...woFileJSONDownload[rowindex]?.headlines];
        if (headlineindex > -1) {
          the_headlines.splice(headlineindex, 1);
        } else {
          if (the_headlines.length >= 15) {
            return toast.error(
              "You are trying to select more than 15 headlines. Please uncheck existing headlines before selecting new headline to add to your ad group"
            );
          } else {
            the_headlines = [...the_headlines, headline_obj];
          }
        }

        woFileJSONDownloadTemp[rowindex] = {
          ...woFileJSONDownloadTemp[rowindex],
          headlines: the_headlines,
        };
      } else {
        woFileJSONDownloadTemp.push({
          index,
          ...woFileJSON[index],
          headlines: [headline_obj],
        });
      }
      dispatch(setWoFileJSONDownload(woFileJSONDownloadTemp));
    };
    let WoFileJsonDownload = woFileJSONDownload[index];
    let checkedstatus =
      WoFileJsonDownload?.headlines?.findIndex(
        (item) => item?.headline == val.headline
      ) > -1
        ? true
        : false;
    return (
      <div key={ind} className="exp-row flex items-center">
        <div className="exp-row-item flex items-center">
          <div className="col-numb">{ind + 1}</div>
          <input
            onClick={(e) => {
              handleClick(val);
            }}
            checked={checkedstatus}
            type="checkbox"
            className="check-box w-5 h-5"
          />
        </div>
        <div className="exp-row-item flex items-center justify-center">
          <Tooltip
            title="Character count"
            position="top"
            arrow="true"
            animation="scale"
          >
            <button className="btn button" disabled={true}>
              {val?.headline?.trim().length}
            </button>
          </Tooltip>
        </div>
        <div className="exp-row-item flex items-center">
          <div className="exp-row-tag">
            {val?.headline ? val?.headline : "--"}
          </div>
        </div>
        <div className="exp-row-item flex items-center">
          <Tooltip
            title="Set Headline Postions"
            position="top"
            arrow="true"
            animation="scale"
          >
            <div className="exp-row-tag">
              <WofDropDown
                headlinesPositionList={headlinesPositionList}
                handlePosition={(e) => handlePosition(e, checkedstatus, val)}
                headlinePositionVal={val}
              />
            </div>
          </Tooltip>
        </div>
        {/* <div className="exp-row-item flex items-center">
          <div className="exp-row-tag">{val ? val : "--"}</div>
        </div> */}
        {/* <div className="exp-row-item flex items-center">
          {categoryData?.isLoading ? (
            <Spinner />
          ) : (
            <div className="exp-row-tag">
              {categoryType ? categoryType : ""}
            </div>
          )}
        </div> */}
        <div className="exp-row-item flex items-center justify-end">
          {/* <div
            className="eye-icon flex items-center justify-center mr-3 cursor-pointer"
            onClick={(e) => {
              submitCategoryRequestHeadline(val);
            }}
          >
            <EyeOffIcon />
          </div> */}
          <Tooltip
            title="Update Headline"
            position="top"
            arrow="true"
            animation="scale"
          >
            <div
              className="edit-icon flex items-center justify-center cursor-pointer"
              onClick={(e) => {
                setOpenUpdateHeadline(true);
                setSelectedVal({ checkedstatus, val });
                setSelectedHeadlineVal({
                  desc: val?.headline.trim(),
                  ValInd: ind,
                });
                setUpdatedHeadlineVal(val?.headline.trim());
              }}
            >
              <EditIcon />
            </div>
          </Tooltip>
        </div>

        {/* <div className="exp-row-item flex items-center justify-center">
      <div className="btn button">New Variants</div>
    </div>
    <div className="exp-row-item flex items-center justify-center">
      <input
        type="text"
        className="txt-seach-key"
        placeholder="Include Keywords"
      />
    </div>
    <div className="exp-row-item flex items-center justify-center">
      <Select
        defaultValue={selectedStyle}
        value={selectedStyle}
        onChange={setSelectedStyle}
        getOptionLabel={(option) => option.display}
        getOptionValue={(option) => option.value}
        options={StyleList}
        className="select"
        id="adGroup"
        name="adGroup"
        placeholder="Select Ad-Group"
      />
    </div> */}
      </div>
    );
  };

  return (
    <>
      <div key={index} className="row-expend-block flex flex-col">
        <div className="expend-row-headline flex flex-col items-center">
          <div className="expend-row-headline-top flex">
            <div className="input-field flex flex-col w-2/5">
              <div className="input-tag text-sm font-semibold">
                Product/Service Name
              </div>
              <div className="input-tag mb-2 text-sm font-semibold text-red-700">
                Enter Name Below
              </div>
              <textarea
                name="pName"
                type="text"
                className="txt-seach-key min-h-[100px] max-h-[100px] overflow-hidden"
                placeholder="Product/Service Name"
                value={userInputProductName}
                onChange={(e) => setUserInputProductName(e.target.value)}
                onBlur={handleBlur}
              />
            </div>
            <div className="input-field flex flex-col w-full">
              <div className="input-tag text-sm font-semibold">
                Product/Service Summary
              </div>
              <div
                className="input-tag flex mb-2 text-sm font-semibold text-red-700 cursor-pointer"
                onClick={(e) => setOpenGenerateUrl(true)}
              >
                Click Cloud Icon to generate a summary of your landing page{" "}
                <div className="icon cursor-pointer ml-2 h-2 w-2">
                  <CloudIcon />
                </div>
              </div>
              <textarea
                type="text"
                className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
                placeholder="Product/Service Summary"
                value={userInputProductDescription}
                onChange={(e) => setUserInputProductDescription(e.target.value)}
              />
            </div>
            <input
              type="submit"
              value={data.isLoading ? "Processing..." : "Generate Ideas"}
              className="btn button h-12 mt-8"
              onClick={(e) => submitCompletionRequestHeadline(e)}
            />
          </div>
          <div className="expend-row-headline-top flex mt-5">
            <div className="input-field flex flex-col w-full">
              <div className="input-tag text-sm font-semibold">
                Product/ Tone of Voice
              </div>
              <div
                className="input-tag flex mb-2 text-sm font-semibold text-red-700 cursor-pointer"
                onClick={(e) => setOpenGenerateUrl(true)}
              >
                Click Cloud Icon to generate a tune of voice of your landing
                page
                <div className="icon cursor-pointer ml-2 h-2 w-2">
                  <CloudIcon />
                </div>
              </div>
              <textarea
                type="text"
                className="txt-seach-key w-full min-h-[100px] max-h-[100px] overflow-hidden"
                placeholder="Product / Tune of voice"
                value={useInputProductToneVoice}
                onChange={(e) => setUseInputProductToneVoice(e.target.value)}
              />
            </div>
          </div>

          {/* <div className="categories-block">
            {categoriesList.map((cItem, cIndex) => (
              <div
                key={cIndex}
                className="category-box flex flex-col items-center justify-center"
              >
                <div className="category-name">{cItem.name}</div>
                <div className="category-numb">2/15</div>
                <input
                  type="submit"
                  value={data.isLoading ? "Loading..." : "Generate Ideas"}
                  className="btn-idea button"
                  onClick={(e) => {
                    submitCompletionRequestHeadlineCategory(cItem.name);
                  }}
                />
              </div>
            ))}
          </div> */}
          <div className="expend-tag">Headlines</div>
          {/* <div className="action items-center justify-center mb-10">
            <button className="btn button font-medium">
              Post Changes to Google Ads
            </button>
          </div> */}
        </div>
        <div className="expend-table-section flex flex-col">
          {data?.isLoading ? (
            <AiLoader />
          ) : (
            <>
              {item?.length ? (
                <>
                  {item?.map((val, ind) => (
                    <HeadlineRow val={val} ind={ind} />
                  ))}
                </>
              ) : (
                <div className="text-lg font-semibold text-center mt-20 mb-10 text-rose-700">
                  No headlines were generated. Please press “Generate Ideas” for
                  more output.
                </div>
              )}
            </>
          )}
        </div>
        <Modal
          open={openUpdateHeadline}
          onClose={() => setOpenUpdateHeadline(false)}
        >
          <UpdateWoHeadline
            setOpen={setOpenUpdateHeadline}
            updatedHeadlineVal={updatedHeadlineVal}
            setUpdatedHeadlineVal={setUpdatedHeadlineVal}
            // val={val}
            // editTitleinWoFileJSONDownload={editTitleinWoFileJSONDownload}
          />
        </Modal>
      </div>
    </>
  );
};

export default WoRowExpendHeadline;
