import React from "react";

function FeedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      viewBox="0 0 24 24"
    >
      <path d="M5 20a1 1 0 100-2 1 1 0 000 2zM4 4a16 16 0 0116 16M4 11a9 9 0 019 9"></path>
    </svg>
  );
}

export default FeedIcon;
