import React from "react";

const TermsCondition = () => {
  return (
    <div className="terms-condition-page bg-themeColor min-h-screen px-3 py12">
      <div className="wrap wrapWidth flex flex-col">
        <div class="terms-container">
          <h1>RSAPro: Terms and Conditions</h1>
          <div>
            <p>
              Welcome to RSAPro, a SAAS tool provided by CB Digital Marketing
              Limited, a company registered in England with company number
              12257552 (trading as CapitalC). These terms and conditions (the
              "Terms") govern your use of the RSAPro service (the "Service") and
              constitute a binding agreement between you and CB Digital
              Marketing Limited ("the Company"). By using the Service, you agree
              to be bound by these Terms. If you do not agree to these Terms,
              you may not use the Service. Please read these Terms carefully and
              contact us if you have any questions.
            </p>
          </div>
          <ol>
            <li>
              <h2>1. Description of the Service</h2>
              <p>
                RSAPro is an AI-powered ad copy generation tool that assists
                users in generating ad copy for Google Ads. The tool uses
                artificial intelligence and machine learning algorithms to
                generate ad copy elements. The Company does not guarantee the
                quality or effectiveness of the ad copy generated.
              </p>
            </li>
            <li>
              <h2>2. Intellectual Property Rights</h2>
              <p>
                All intellectual property rights in the Service, including but
                not limited to any software, algorithms, and proprietary
                technology used by the Service, shall remain the sole and
                exclusive property of the Company or its licensors. The user
                shall not acquire any intellectual property rights in the
                Service, including but not limited to any software, algorithms,
                and proprietary technology used by the Service, except for the
                limited license granted in these Terms. The user acknowledges
                that access to the Service is provided on a fully commercial
                basis and not on a free or open-source basis. The user shall not
                use, copy, modify, distribute, or create derivative works based
                on the Service, including but not limited to any software,
                algorithms, or proprietary technology used by the Service,
                except as expressly authorized by the Company in writing.
              </p>
            </li>
            <li>
              <h2>3. User Output Data</h2>
              <p>
                The user retains all ownership rights to the output generated by
                them using the service. However, the Company may use the user
                output data for training and quality control purposes.
              </p>
            </li>
            <li>
              <h2>4. Copyright and Legal Restrictions</h2>
              <p>
                The user is solely responsible for ensuring that the generated
                ad copy does not violate any copyright or legal restrictions.
                The Company does not guarantee that the output will not infringe
                any third-party rights, and the user agrees to indemnify and
                hold the Company harmless from any and all claims arising from
                any violation of copyright or legal restrictions.
              </p>
            </li>
            <li>
              <h2>5. Privacy</h2>
              <p>
                The Company collects and uses personal data in accordance with
                its privacy policy. By accessing or using the Service, you agree
                to the terms of the Company’s privacy policy.
              </p>
            </li>
            <li>
              <h2>6. Prohibited Actions</h2>
              <p>
                The user shall not attempt to reverse engineer, decompile, or
                create derivative works from the Service or any part of it. The
                user shall not use the Service for any illegal or unauthorized
                purpose or attempt to access any data or information beyond what
                is necessary for the proper use of the Service. The user shall
                not use the Service in any way that could damage, disable,
                overburden, or impair the Service, nor shall the user attempt to
                gain unauthorized access to any Service user account or computer
                system.
              </p>
            </li>
            <li>
              <h2>7. Limitations of Liability</h2>
              <p>
                The Company shall not be liable for any indirect, incidental,
                special, or consequential damages arising out of or in
                connection with the use or inability to use the Service,
                including, but not limited to, any damages resulting from loss
                of use, data, or profits, whether or not the Company has been
                advised of the possibility of such damages.
              </p>
            </li>
            <li>
              <h2>8. Termination</h2>
              <p>
                The Company may terminate your access to the Service at any time
                for any reason, with or without notice.
              </p>
            </li>
            <li>
              <h2>9. Governing Law and Jurisdiction</h2>
              <p>
                These Terms and your use of the Service shall be governed by and
                construed in accordance with the laws of England and Wales,
                without giving effect to any principles of conflicts of law. You
                agree that any dispute arising out of or in connection with
                these Terms or your use of the Service shall be resolved
                exclusively in the courts of England and Wales
              </p>
            </li>
            <li>
              <h2>10. Changes to Terms</h2>
              <p>
                The Company may modify these Terms at any time, and such
                modifications shall be effective immediately upon posting. Your
                continued use of the Service after any modifications to these
                Terms shall constitute your acceptance of such modifications.
              </p>
            </li>
            <li>
              <h2>11. Changes to Fees and Access</h2>
              <p>
                The Company reserves the right to start charging a fee for the
                use of the Service or to change the fees charged for the
                Service, at any time, with or without notice. The Company may
                also restrict or limit access to certain features or
                functionality of the Service to paying customers only. Any such
                changes to fees or access shall be effective immediately upon
                posting the updated terms and conditions on the Service's
                website. The user's continued use of the Service following such
                changes shall constitute acceptance of such changes.
              </p>
            </li>
            <li>
              <h2>12. Disclaimer of Warranties</h2>
              <p>
                The Service is provided "as is" and "as available" without
                warranties of any kind, either express or implied, including,
                but not limited to, implied warranties of merchantability,
                fitness for a particular purpose, or non-infringement. The
                Company does not warrant that the Service will meet your
                requirements and be uninterrupted, error-free, or secure.
              </p>
            </li>
            <li>
              <h2>13. Indemnification</h2>
              <p>
                The user agrees to indemnify and hold the Company, its
                affiliates, officers, directors, agents, and employees harmless
                from any and all claims, damages, losses, liabilities, costs,
                and expenses (including reasonable attorneys' fees) arising from
                or in connection with the user's use of the Service, any content
                generated by the Service, or any violation of these Terms.
              </p>
            </li>
            <li>
              <h2>14. Modification or Discontinuation of Service</h2>
              <p>
                The Company reserves the right to modify, suspend, or
                discontinue the Service at any time, with or without notice. The
                Company shall not be liable to the user or any third party for
                any modification, suspension, or discontinuation of the Service.
              </p>
            </li>
            <li>
              <h2>15. Assignment</h2>
              <p>
                The user may not assign or transfer these Terms or any rights
                granted herein without the prior written consent of the Company.
                The Company may assign or transfer these Terms, in whole or in
                part, without restriction.
              </p>
            </li>
            <li>
              <h2>16. Severability</h2>
              <p>
                If any provision of these Terms is found to be invalid or
                unenforceable, the remaining provisions shall remain in full
                force and effect.
              </p>
            </li>
            <li>
              <h2>17. Waiver</h2>
              <p>
                The failure of the Company to enforce any right or provision of
                these Terms shall not be deemed a waiver of such right or
                provision.
              </p>
            </li>
            <li>
              <h2>18. Headings</h2>
              <p>
                The headings in these Terms are for convenience only and shall
                not affect the interpretation of these Terms.
              </p>
            </li>
            <li>
              <h2>19. </h2>
              <p>
                All provisions of these Terms which by their nature should
                survive termination, shall survive termination, including,
                without limitation, ownership provisions, warranty disclaimers,
                indemnity, and limitations of liability.
              </p>
            </li>
            <li>
              <h2>20. Independent Contractors</h2>
              <p>
                The relationship between the user and the Company is that of
                independent contractors, and nothing contained in these Terms
                shall be construed as creating any partnership, joint venture,
                agency, or employment relationship between the user and the
                Company. The user shall have no authority to bind the Company,
                and the user shall not represent itself as an agent or
                authorized representative of the Company. The user shall not
                make any warranties, representations, or promises on behalf of
                the Company, and the Company shall have no liability for any
                such warranties, representations, or promises made by the user.
              </p>
            </li>
            <li>
              <h2>21. Entire Agreement</h2>
              <p>
                These Terms constitute the entire agreement between you and the
                Company with respect to the Service and supersede all prior or
                contemporaneous communications and proposals, whether oral or
                written, between you and the Company. <br />
                <br /> Contact Information: If you have any questions or
                concerns about these Terms, please contact the Company at
                app@capitalc.agency
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
