import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../store/services/authService";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const initialValues = {
    password: "",
    confirmPassword: "",
    token: token ? token : "",
  };

  const validationSchema = Yup.object().shape({
    token: Yup.string(),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
        "Password must contain minimum 8 characters, at least one letter and one number"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const [reset, response] = useResetPasswordMutation();

  const errors = response?.error?.data?.errors
    ? response?.error?.data?.errors
    : [];
  const userResetFunction = (e) => {
    reset(e);
  };

  useEffect(() => {
    if (response.isSuccess) {
      toast.success(`${response?.data?.message}`);
      navigate("/");
    }
  }, [response.isSuccess]);

  return (
    <div className="bg-themeColor h-screen flex justify-center items-center px-4">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={userResetFunction}
      >
        {(formik) => (
          <Form className="inner-box flex flex-col bg-white p-5 w-[500px] sm:[300px] md:[500px] lg:[500px] rounded">
            <h3 className="mb-4 text-black capitalize font font-bold text-lg">
              Enter New Password
            </h3>
            {errors.length > 0 &&
              errors.map((error, key) => (
                <div key={key}>
                  <p className="alert-danger">{error.msg}</p>
                </div>
              ))}

            <div className="mb-4 flex flex-col">
              <Field
                type="password"
                id="password"
                name="password"
                placeholder="password"
                className="w-full font-medium text-lg bg-[#eee] p-2 rounded outline-none text-black1"
              />
              <ErrorMessage name="password" />
            </div>

            <div className="mb-4 flex flex-col">
              <Field
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="confirmPassword"
                className="w-full font-medium text-lg bg-[#eee] p-2 rounded outline-none text-black1"
              />
              <ErrorMessage name="confirmPassword" />
            </div>

            <div className="mb-4">
              <input
                type="submit"
                value={response.isLoading ? "Loading..." : "Update Password"}
                className="bg-[#133250] w-full p-4 rounded text-white uppercase font-semibold cursor-pointer"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
